import { Pipe, PipeTransform } from '@angular/core';
import { ICurrencyItemModel }  from "../models/currency-item.model";

@Pipe({ name: 'currencySymbol', pure: true })
export class CurrencySymbolPipe implements PipeTransform {
  private cachedData: string = '';
  private cachedInput = '';

  transform(value: string, currencyList?: ICurrencyItemModel[]): string {
    if (value == this.cachedInput) {
      return this.cachedData;
    }
    this.cachedInput = value;
    this.cachedData = (value != undefined ? currencyList.find(sel => sel.code == value).symbol : "");
    return this.cachedData;
  }
}
