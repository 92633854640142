import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'textColorClass', pure: true })
export class TextColorClassPipe implements PipeTransform {

  transform(x: number, y: number): string {
    if (x <= y) {
      return "text-success";
    } else {
      return "text-warning";
    }
  }

}
