import { Component, Input, OnInit, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";

import { NotificationService } from "../services/notification.service";

@Component({
	selector: "notification",
	template: `
		<div class="sidebar sidebar-right sb-active padding-xs sb-slidebar sb-right" [ngClass]="{ 'sidebar-right-open': show }">
			<h6 class="block-underline">Notifications</h6>

			<div *ngFor="let item of notifications; let i=index;" class="row">
				<div class="col-xs-10">{{item}}</div>
				<div class="col-xs-2"><a (click)="dismissNotification(i)"><span class="fas fa-times text-danger"></span></a></div>
			</div>
		</div>
	`,
	styles: [`
		.sidebar-right {
			right: -354px;
			width: 354px;
		}
		.sidebar-right-open {
			right: 0;
		}
	`]
})
export class NotificationComponent implements OnInit, OnDestroy {
	@Input() show: boolean;
	notifications: string[] = [];

	private notificationServiceSubscription: Subscription;

	constructor(private notificationService: NotificationService) { }

	ngOnInit() {
		this.notificationServiceSubscription = this.notificationService.notification.subscribe(
			notification => {
				notification = notification;
				this.notifications = this.notificationService.notifications;
			}
		);
	}

	ngOnDestroy() {
		this.notificationServiceSubscription.unsubscribe();
	}

	dismissNotification(index: number) { this.notificationService.removeNotification(index); }
}
