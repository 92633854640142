import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";

import { NotificationService } from "../services/notification.service";
import { IUserModel } from "../models/user.model";

@Component({
	selector: "navbar",
	template: `
		<nav class="navbar navbar-fixed-top sb-slide">
			<div class="container">

				<a class="nav-text pull-left sb-toggle-left" (click)="toggleMenu()" data-automation-navbar-menu-a><i class="fa fa-bars"></i> Menu</a>

				<a routerLink="/dashboard" class="brand" data-automation-navbar-brand-a>
					<img class="hidden-xs" src="assets/img/logo/sampleOne_logo.svg" alt="Logo">
					<img class="visible-xs" src="assets/img/logo/s1_iso.svg" alt="Logo">
				</a>

				<a class="nav-text pull-right sb-toggle-right" (click)="toggleNotification()" [hidden]="!notificationCount" data-automation-navbar-notification-a>
					<span class="badge badge-warning font-size-xs">{{notificationCount}}</span>
				</a>

				<a class="nav-text user-info dropdown pull-right" data-automation-user-info-a>
					<!--<img class="user-avatar" src="{{user?.employeeDirectoryLink}}" alt="User Avatar" style="border-radius: 20px;">-->
					<span class="user-name hidden-xs">{{user?.displayName}}</span>
				</a>
			</div>
		</nav>
` })
export class NavbarComponent implements OnInit, OnDestroy {
	@Input() user: IUserModel;
	@Input() showMenu: boolean;
	@Input() showNotification: boolean;

	@Output() onShowMenu = new EventEmitter<boolean>();
	@Output() onShowNotification = new EventEmitter<boolean>();

	notificationCount = 0;

	private notificationServiceSubscription: Subscription;

	constructor(private notificationService: NotificationService) { }

	ngOnInit() {
		this.notificationServiceSubscription = this.notificationService.notification.subscribe(
			notification => {
				notification = notification;
				this.notificationCount = this.notificationService.notifications.length;
				if (this.notificationCount === 0) { this.hideNotification(); }
			}
		);
	}

	ngOnDestroy() {
		this.notificationServiceSubscription.unsubscribe();
	}

	toggleMenu() {
		this.showMenu = !this.showMenu;
		this.onShowMenu.emit(this.showMenu);

		this.hideNotification();
	};

	toggleNotification() {
		this.showNotification = !this.showNotification;
		this.onShowNotification.emit(this.showNotification);

		this.showMenu = false;
		this.onShowMenu.emit(this.showMenu);
	};

	private hideNotification() {
		this.showNotification = false;
		this.onShowNotification.emit(this.showNotification);
	}
}
