export enum BillingStateEnum {
  NotReadyForCostPublish = "Not ready for cost publish",
  ReadyForCostPublish = "Ready for cost publish",
  AwaitingSupplierApproval = "Awaiting supplier approval",
  Finalized = "Finalized",
  MonthlyBilling = "Monthly billing",
  MonthlyBillingComplete = "Monthly billing complete",
  NoPublishNeeded = "No publish needed",
  NoPublishNeededComplete = "No publish needed complete"
}
