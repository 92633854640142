import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

import { AppSettings } from "../settings/app.settings";
import { IUserModel, UserModel } from "../models/user.model";
import { UserService } from "./user.service";

@Injectable()
export class UserStorageService {
	private userSubject = new Subject<IUserModel>();
	private userInProgress: number = 0;
	private _user = new UserModel(this.appSettings);

	get user() { return this._user; };
	userSubscription = this.userSubject.asObservable();

	constructor(
		private appSettings: AppSettings,
		private userService: UserService
	) { }

	getUser(userId: number): void {
		if (!userId) {
			this._user = new UserModel(this.appSettings);
			this.userSubject.next(this.user);
		} else if ((this.user.userId && userId === this.user.userId) || userId === this.userInProgress) {
			return;
		} else {
			this.userInProgress = userId;
			this.userService.getUser(userId).then(user => {
				this._user = new UserModel(this.appSettings, user);
				this.userInProgress = 0;
				this.userSubject.next(this.user);
			}, () => { });
		}
	}
}
