
import {of as observableOf,  Subject } from 'rxjs';
import {catchError} from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { ISubsampleModel, SubsampleModel } from "../models/subsample.model";
import { SubsampleService } from "./subsample.service";

@Injectable()
export class SubsampleStorageService {
	private subsampleSubject = new Subject<ISubsampleModel>();
	protected subsampleId: number;
	private subsampleInProgress: number = 0;
	private _subsample: ISubsampleModel = {};

	get subsample() { return this._subsample; }
	subsampleSubscription = this.subsampleSubject.asObservable();

	constructor(private subsampleService: SubsampleService) {
  }

	getSubsampleDetails(subsampleId: number) {
		this.subsampleId = subsampleId;
		if (!subsampleId) {
			this._subsample = {};
			this.subsampleSubject.next(this._subsample);
		} else if (subsampleId === this._subsample.id || subsampleId === this.subsampleInProgress) {
			return;
		} else {
			this.subsampleInProgress = subsampleId;
			this.subsampleService
				.getSubsampleDetails(subsampleId).pipe(
				catchError(() => observableOf(<ISubsampleModel>{})))
				.subscribe(subsample => {
					if (!subsample || !subsample.id) { subsample = {}; }

					this.subsampleId = subsample.id || 0;
					this._subsample = subsample || {};

					this.subsampleInProgress = 0;
					this.subsampleSubject.next(this._subsample);
				});
		}
	}

	storeSubsample(subsample: ISubsampleModel) {
        this._subsample = new SubsampleModel(subsample || {});
		this.subsampleSubject.next(this._subsample);
	}
}
