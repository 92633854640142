
//import { Http, Headers, Response } from "@angular/http";
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { of as observableOf, Observable } from "rxjs";

import { AppSettings } from "../settings/app.settings";
import { Cookies } from "./cookies";
import { AuthenticationService } from "../services/authentication.service";
import { LoadingAnimationService } from "../services/loading-animation.service";
import { UserTypeEnum } from "../enums/user-type.enum";
import { IUserCookieModel } from "../models/user-cookie.model";
import { map, catchError } from "rxjs/operators";

export interface ITokenRequest {
    user: IUserCookieModel;
    url?: string;
    keepMeLoggedIn?: boolean;
    regenerate?: boolean;
    loginLocally?: boolean;
}

export interface ITokenResponse {
    access_token?: string;
    expires_in?: number;
    enc_key?: string;
    data?: Object;
    token?: ITokenModel;
    status?: string;
    user_id?: string;
    user_type?: string;
    user_name?: string;
    error?: string;
    error_description?: string;
}

export interface ITokenModel {
    userType: UserTypeEnum;
    encKey: string;
    password: string;
    userName: string;
    encKeyExpires: number;
    accessToken: string;
    userId: number;
    empId: number;
}

export class LoginToken {
    constructor(
        private appSettings: AppSettings,
        private authenticationService: AuthenticationService,
        private httpClient: HttpClient,
        private loadingAnimationService?: LoadingAnimationService
    ) { }

    private static regenerating: Observable<any> | undefined;

  private tokenResponse(params: ITokenRequest, res: HttpResponse<ITokenResponse>): ITokenResponse {
        const responseToken = res.body as ITokenResponse;
        const userType = res.headers.get("UserType") || responseToken.user_type;
        const userName = res.headers.get("UserName") || responseToken.user_name;
        const userId = res.headers.get("ApplicationUserId") || responseToken.user_id;
        const encKey = res.headers.get("EncKey") || responseToken.enc_key;
        // const encKeyExpires = res.headers.get("EncKeyExpires");

              
        const accessToken = responseToken.access_token || "";
        const accessTokenExpire = responseToken.expires_in;

        const tokenExpire = parseInt(((accessTokenExpire || 600) * 0.95).toString(), 10) || 600;

        let error = "";

        if (!accessToken || !accessTokenExpire) {
            error = "Invalid access token received";
        } else if (!userType || !userName || !userId || !encKey/* || !encKeyExpires*/) {
            error = "Invalid user data received";
    }

        if (!error && params.loginLocally) {
            error = this.setCookies({
                userType: userType,
                userName: userName,
                userId: userId,
                encKey: encKey,
                accessToken: accessToken,
                tokenExpire: tokenExpire,
                keepMeLoggedIn: !!params.keepMeLoggedIn,
                regenerate: !!params.regenerate
            });
        }

        if (this.loadingAnimationService) { this.loadingAnimationService.hide(); }

        LoginToken.regenerating = undefined;

        return <ITokenResponse>(
            error
                ? {
                    error: error,
                    error_description: error
                }
                : {
                    access_token: accessToken,
                    expires_in: tokenExpire,
                    enc_key: encKey,
                    user_id: userId,
                    user_type: userType,
                    user_name: userName
                }
        );
    }

    private setCookies(params: {
        userType: string,
        userName: string,
        userId: string,
        encKey: string,
        // encKeyExpires: string,
        accessToken: string,
        tokenExpire: number,
        keepMeLoggedIn: boolean,
        regenerate: boolean
    }): string {
        const oneDay = 60 * 60 * 24;
        const oneYear = oneDay * 365;

        Cookies.setCookie({
            name: this.appSettings.CookieAuthToken + "_" + params.userType,
            value: params.accessToken,
            expire: params.tokenExpire,
            path: "/"
        });

        //UIComponents look for the bearer in UIaccessToken
        Cookies.setCookie({
          name: "UIaccessToken",
          value: params.accessToken,
          expire: params.tokenExpire,
          path: "/"
        });

        if (!params.regenerate) {
            Cookies.setCookie({
                name: this.appSettings.CookieUserType,
                value: params.userType,
                expire: oneYear /* tokenExpire */,
                path: "/"
            });

            Cookies.setCookie({
                name: this.appSettings.CookieUserData + "_" + params.userType,
                value: JSON.stringify({
                    userName: params.userName,
                    userType: +params.userType,
                    userId: +params.userId,
                    encKey: params.encKey,
                }),
                expire: params.keepMeLoggedIn ? oneDay : params.tokenExpire /* encKeyExpires || 600 */,
                path: "/"
            });

        }

        return "";
    }

    generate(params: ITokenRequest): Observable<ITokenResponse> {
        if (this.loadingAnimationService) { this.loadingAnimationService.show(); }

        let body = "grant_type=password";
        if (params.user.userName) { body += "&username=" + String(params.user.userName); }
      if (params.user.password) { body += "&password=" + String(params.user.password); }

        let requestHeaders = new HttpHeaders();
        requestHeaders = requestHeaders.append("Content-Type", "application/x-www-form-urlencoded; charset=UTF-8");
        if (params.user.userType) { requestHeaders = requestHeaders.append("UserType", String(params.user.userType)); }
        if (params.user.encKey) { requestHeaders = requestHeaders.append("EncKey", String(params.user.encKey)); }
        if (params.user.employeeId) { requestHeaders = requestHeaders.append("EmpId", String(params.user.employeeId)); }
        if (params.user.hash) { requestHeaders = requestHeaders.append("Hash", String(params.user.hash)); }
        if (!!params.url) { requestHeaders = requestHeaders.append("Url", String(params.url)); }

        return (this.httpClient
            .post<ITokenResponse>(this.appSettings.AuthenticateTokenURI, body, { headers: requestHeaders, observe: 'response' })
            .pipe(
                map(res => this.tokenResponse(params, res)
                ),
              catchError(error => {
                if (this.loadingAnimationService) { this.loadingAnimationService.hide(); }
                let retErr: ITokenResponse = {};
                if (!error.ok) {

                  switch (typeof error.error) {
                    case "string":
                      retErr = {
                        error: error.error,
                        error_description: String(error.message)
                      }; break;
                    case "object":
                      retErr = {
                        error: error.error,
                        error_description: String(error.error.error_description)
                      }; break;
                    default:
                      retErr = {
                        error: "unknown error",
                        error_description: String(error)
                    }; break;

                  }
                  return observableOf(retErr);
                }
              }))
        );
    }

  regenerate(): Observable<ITokenResponse> | undefined {        
        if (LoginToken.regenerating) { return; }
        LoginToken.regenerating = this.generate({
            user: this.authenticationService.userData || {},
            regenerate: true,
            loginLocally: true
        });
        return LoginToken.regenerating;
    }
}
