import { Injectable } from "@angular/core";

import { AppSettings } from "../settings/app.settings";
import { HttpClientService } from "../utilities/http.client";
import { Observable } from "rxjs";
import { IUserModel } from "../models/user.model";
import { IDictionary } from "./../interfaces/dictionary.interface";

@Injectable()
export class UserService {
	constructor(
		private appSettings: AppSettings,
		private http: HttpClientService
	) { }

	private urls: IDictionary<any> = {
		"getUser": this.appSettings.AdminApiPath + "UserInfo/{userId}",
		"getUserByEmployeeId": this.appSettings.AdminApiPath + "User/{employeeId}",
		"getUserList": this.appSettings.AdminApiPath + "UserList",
	};

	getUser(userId: number): Promise<IUserModel> {
		return this.http.get(this.urls["getUser"], { "userId": userId });
	}

  getUserObservable(userId: number): Observable<IUserModel> {
    return this.http.getSubscription(this.urls["getUser"], { "userId": userId });
  }

	getUserByEmployeeId(employeeId: number): Promise<IUserModel> {
		return this.http.get(this.urls["getUserByEmployeeId"], { "employeeId": employeeId });
	}

	getUserList(): Observable<IUserModel[]> {
		return this.http.getSubscription(this.urls["getUserList"], {});
	}

}
