import { ArrayExtensions } from "../utilities/array.extensions";

import { AppSettings } from "../settings/app.settings";
import { ICountryModel } from "./country.model";
import { SurveyPlatformEnum, SurveyStatusEnum } from "../enums/survey-status.enum";
import { OsmType } from "../enums/osm-type.enum";
import { IEventFieldMonitoringInfoModel } from "./event-field-monitoring-info.model";
import { IUserModel, UserModel } from "./user.model";
import { IRange } from "./range.model";

export interface IEventModel {
	id?: number;
	name?: string;
	subsampleCount?: number;
	clientName?: string;
	divisionName?: string;
	wsblName?: string;
	jobBookIds?: string[];
	itemRange?: IRange;
	event?: string;
	jobNumber?: string;
  externalJobNumber?: string;
	status?: SurveyStatusEnum;
	startTime?: Date;
	endTime?: Date;
	creationDate?: Date,
	primaryProjectManager?: IUserModel;
	secondaryProjectManager?: IUserModel;
	fieldCoordinator?: IUserModel;
  primaryScriptWriter?: IUserModel;
  additionalProjectManager?: IUserModel;
	countries?: ICountryModel[];
	info?: IEventFieldMonitoringInfoModel;
	isTemplate?: boolean;
	allowMobile?: boolean;
	allowTablet?: boolean;
	allowComputer?: boolean;
	usersInfo?: IUserModel[];
	researchTypeId?: number;
	researchTypeName?: string;
	misProductDesc?: string;
	supplierInvoicingId?: number;
	billingEntityName?: string;
	osmType?: string;
  osmTypeId?: OsmType;
  s1CostsPageDeployDate?: Date;
  surveyPlatform?: SurveyPlatformEnum;
  sampleOnlyRedirectKey?: string;
  importSovalFlag?: boolean;
  generateSovalKeyForSOEventsStartDate?: Date;
  surveyPlatformName?: string;
  subsampleList?: string;
  jobNumberList?: string[];
  testFlag?: boolean;
}

export class EventModel implements IEventModel {
  private primaryProjectManagerId = -1;
  private secondaryProjectManagerId = -1;
  private ssrtId = -1;
  private primarySwId = -1;
  private additionalProjectManagerId = -1;
  id = 0;
  name = "";
  subsampleCount = 0;
  clientName = "";
  divisionName = "";
  wsblName = "";
  jobBookIds: string[] = [];
  itemRange = { min: 0, max: 0 };
  event = "";
  jobNumber = "";
  externalJobNumber = "";
  status = SurveyStatusEnum.None;
  startTime = new Date();
  endTime = new Date();
  creationDate = new Date();
  primaryProjectManager = new UserModel(this.appSettings);
  secondaryProjectManager = new UserModel(this.appSettings);
  fieldCoordinator = new UserModel(this.appSettings);
  primaryScriptWriter = new UserModel(this.appSettings);
  additionalProjectManager = new UserModel(this.appSettings);
  countries: ICountryModel[] = [];
  info: IEventFieldMonitoringInfoModel = {};
  isTemplate = false;
  allowMobile = false;
  allowTablet = false;
  allowComputer = false;
  researchTypeId = 0;
  misProductDesc = "";
  researchTypeName = "";
  supplierInvoicingId = 0;
  billingEntityName = "";
  osmType = "";
  osmTypeId?: OsmType;
  s1CostsPageDeployDate = new Date();
  surveyPlatform = SurveyPlatformEnum.NoPlatform;
  sampleOnlyRedirectKey = "";
  importSovalFlag = false;
  generateSovalKeyForSOEventsStartDate = new Date();
  surveyPlatformName: string;
  subsampleList = "";
  jobNumberList : string[] = [];
  testFlag = false;
  constructor(private appSettings: AppSettings, model?: IEventModel) {
    ArrayExtensions.deepCopy.apply(this, [model]);

    switch ((this.osmType || "").toLowerCase()) {
      case "noosm":
        this.osmTypeId = OsmType.NoOSM;
        break;
      case "isay":
        this.osmTypeId = OsmType.ISAY;
        break;
      case "internalandexternalsample":
        this.osmTypeId = OsmType.InternalAndExternalSample;
        break;
      case "sampleone":
        this.osmTypeId = OsmType.SampleOne;
        break;
      default:
        this.osmTypeId = OsmType.Unknown;
        break;
    }

    switch (this.surveyPlatform.toString()) {
      case "SurveyCreator":
        this.surveyPlatformName = "Survey Creator";
        break;
      case "SampleOnly":
        this.surveyPlatformName = "Sample Only";
        break;
      case "NoPlatform":
        this.surveyPlatformName = "No Platform";
        break;
      default:
        this.surveyPlatformName = this.surveyPlatform.toString();
        break;
    }

    if (model && model.usersInfo) {
      for (let u = 0, ul = model.usersInfo.length; u < ul; u++) {
        if ((model.usersInfo[u].userId || 0) > 0) {
          if (model.usersInfo[u].userId == this.primaryProjectManagerId)
            this.primaryProjectManager = new UserModel(this.appSettings, model.usersInfo[u]);

          if (model.usersInfo[u].userId == this.secondaryProjectManagerId)
            this.secondaryProjectManager = new UserModel(this.appSettings, model.usersInfo[u]);

          if (model.usersInfo[u].userId == this.ssrtId)
            this.fieldCoordinator = new UserModel(this.appSettings, model.usersInfo[u]);

          if (model.usersInfo[u].userId == this.primarySwId)
            this.primaryScriptWriter = new UserModel(this.appSettings, model.usersInfo[u]);

          if (model.usersInfo[u].userId == this.additionalProjectManagerId)
            this.additionalProjectManager = new UserModel(this.appSettings, model.usersInfo[u]);
        }
      }
    }
  }

  set primaryPMId(newID: number) {
    this.primaryProjectManagerId = newID;
  }
  set secondaryPMId(newID: number) {
    this.secondaryProjectManagerId = newID;
  }
  set fieldCoordinatorId(newID: number) {
    this.ssrtId = newID;
  }
  set primaryScriptwriterId(newID: number) {
    this.primarySwId = newID;
  } 
  set additionalPMId(newID: number) {
    this.additionalProjectManagerId = newID;
  }
}
