import { ICookieModel } from "../models/cookie.model";
import { Location } from "../utilities/location";
import { IDictionary } from "./../interfaces/dictionary.interface";

export class Cookies {
	static getItem(name: string): string | undefined {
		if (!name || !Cookies.hasItem(name)) { return; }
		return Location.decode(document.cookie.replace(
			new RegExp(
				"(?:^|.*;\\s*)" +
				Location.encode(name).replace(/[\-\.\+\*]/g, "\\$&") +
				"\\s*\\=\\s*((?:[^;](?!;))*[^;]?).*"
			)
			, "$1"));
	}

	static setCookie(cookie: ICookieModel): void {
		if (!cookie.name || /^(?:expires|max\-age|path|domain|secure)$/.test(cookie.name)) { return; }
		let cookieExpire = "";
		if (cookie.expire) {
			switch (typeof cookie.expire) {
				case "number": {
					let expireDate = new Date();
					expireDate.setTime(expireDate.getTime() + cookie.expire * 1000);
					cookieExpire = "; expires=" + expireDate.toUTCString();
				}; break;
				case "string": cookieExpire = "; expires=" + cookie.expire; break;
				case "object": if (cookie.expire.toGMTString) { cookieExpire = "; expires=" + cookie.expire.toGMTString(); } break;
			}
		}
		if (Cookies.hasItem(cookie.name)) {
			Cookies.removeItem(cookie.name);
			Cookies.removeItem(cookie.name, cookie.domain);
		}
		document.cookie =
			Location.encode(cookie.name) + "=" +
			Location.encode(typeof cookie.value === "object" ? JSON.stringify(cookie.value || "") : (cookie.value || "")) +
			cookieExpire +
			(cookie.domain ? "; domain=" + cookie.domain : "") +
			(cookie.path ? "; path=" + cookie.path : "") +
			(cookie.secure ? "; secure" : "");
	}

	static setItems(names: IDictionary<ICookieModel>): void {
		let ndx: string;
		for (ndx in names) {
			if (names.hasOwnProperty(ndx)) {
				Cookies.setCookie(names[ndx]);
			}
		}
	}

	static removeItem(name: string, domain?: string): void {
		if (!name || !Cookies.hasItem(name)) { return; }
		let expiration: Date = new Date();
		expiration.setDate(expiration.getDate() - 1);
		document.cookie = Location.encode(name) + "=; expires=" + expiration.toUTCString() + "; path=/" + (domain ? "; domain=" + domain : "");
	}

	static hasItem(name: string): boolean {
		return (new RegExp("(?:^|;\\s*)" + Location.encode(name).replace(/[\-\.\+\*]/g, "\\$&") + "\\s*\\=")).test(document.cookie);
	}
}
