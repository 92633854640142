export enum SurveyStatusEnum {
	None = 0,
	Pending = 1,
	PendingOrTesting = 2,
	Live = 3,
	Complete = 4,
	Canceled = 5,
	Testing = 6
}

export enum SurveySubsampleCreateSourceEnum {
	CortexLegacy = 0,
	FullServiceRapid = 1,
	SampleOnly = 2,
	FullServiceLegacy = 3,
	FullService = 4,
	FullServiceDigital = 5,
	SampleOnlyDigital = 6
}

interface ISurveyStatusValidation {
    name: string,
    route: string
}

export interface ISurveyStatus {
	name: string;
    id: SurveyStatusEnum;
    validations: ISurveyStatusValidation[];
	colorClass: string;
    toggle?: boolean;
    confirmStatus?: boolean;
}

export const SurveyStatusList: ISurveyStatus[] = [
    { name: "Pending", id: SurveyStatusEnum.Pending, colorClass: "default", validations: [] },
    { name: "Testing", id: SurveyStatusEnum.Testing, colorClass: "warning", validations: [] },
    { name: "Live", id: SurveyStatusEnum.Live, colorClass: "success", validations: [] },
    { name: "Complete", id: SurveyStatusEnum.Complete, colorClass: "info", validations: [] },
    { name: "Canceled", id: SurveyStatusEnum.Canceled, colorClass: "danger", validations: [] }
];

export enum statusOfSetSubsamplesStatusEnum {
    Before = 0,
    Waiting = 1,
    Finish = 2
}

export interface IIdAndPositionInEvent_Subsample {
    id: number,
    countryIndex: number,
    subsampleIndex: number,
    update: boolean
}

export enum SurveyPlatformEnum {
  Dimensions = 0,
  SurveyCreator = 1,
  SampleOnly = 2,
  Askia = 3,
  NoPlatform = 4
}
