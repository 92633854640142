import { Pipe, PipeTransform } from '@angular/core';
import { ISubsampleCostItem }  from "../models/subsample-cost-item.model";
import { BillingStateEnum }    from "../enums/billing-state.enum";

@Pipe({ name: 'supplierBillingState' })
export class SupplierBillingStatePipe implements PipeTransform {
  transform(value?: ISubsampleCostItem[], trigger?: number): string {

    //if no subsamples
    if (value.length == 0)
      return "";
    //check if at least one subsample is in "Not ready.."
    if (value.some(subsample => subsample.billingState == BillingStateEnum.NotReadyForCostPublish))
      return BillingStateEnum.NotReadyForCostPublish;

    //all the other states should always be the same for all subsamples
    if (value.every(subsample => subsample.billingState == value[0].billingState))
      return value[0].billingState;

    //if all are a mixture of monthly billing and completed monthly billing we will show monthly billing
    if (value.every(subsample => subsample.billingState == BillingStateEnum.MonthlyBilling || subsample.billingState == BillingStateEnum.MonthlyBillingComplete))
      return BillingStateEnum.MonthlyBilling

    //and final case, mixture of monthly billing, completed monthly billing and all other statuses should be the same
    if (value.some(subsample => subsample.billingState == BillingStateEnum.MonthlyBilling || subsample.billingState == BillingStateEnum.MonthlyBillingComplete))
      return value.filter(subsample => subsample.billingState != BillingStateEnum.MonthlyBilling && subsample.billingState != BillingStateEnum.MonthlyBillingComplete)[0].billingState;

  }
}
