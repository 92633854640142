import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { HttpClientService } from "../utilities/http.client";
import { AppSettings } from "../settings/app.settings";
import { IADQuestionModel } from "../models/ad-question.model";

@Injectable()
export class ADQuotaService {

	constructor(
		private appSettings: AppSettings,
        private http: HttpClientService
	) { }

	private urls = {
    getStandardQuestions: `${this.appSettings.AudienceDesignerApiPath}locales/{locale}/questions`,
    getQuotaBuilderEnable: `${this.appSettings.AdminApiPath}QuotaBuilderEnable`
	};

  getStandardQuestions = (locale: string, surveyId: number): Observable<IADQuestionModel[]> =>
    this.http.getSubscription(this.urls.getStandardQuestions, { locale: locale, surveyId: surveyId });

  isEnabled = (): Observable<boolean> =>
    this.http.getSubscription(this.urls["getQuotaBuilderEnable"]);
}
