export const AppRoutes = {
  login: { path: "login" },

  dashboard: {
    path: "dashboard",
    // children
    page: {
      param: "page"
    }
  },
  tools: {
    path: "tools",
    convertRespodentIds: { path: "convert-respondentids" },
    decryptRespodentIds: { path: "decrypt-respondentids" },
    encryptRespodentIds: { path: "encrypt-respondentids" }
  },
  admin: {
    path: "admin",
    templates: { path: "templates" },
    administration: { path: "administration" }
  },
  systemSettings: {
    path: "systemSettings",
    templates: { path: "templates" },
    administration: { path: "administration" },
    adminTables: { path: "admin-tables" }
  },
  routerManagement: {
    path: "routerManagement",
    dynamicRouter: { path: "dynamic-router" },
    surveyHealth: { path: "survey-health" },
    healthActionCenter: { path: "health-action-center" }
  },
  troubleshooting: {
    path: "troubleshooting",
    system: { path: "system" },
    subsample: { path: "subsample" },
    respondent: { path: "respondent" }
  },
  reports: {
    path: "reports",
    monthlybilling: { path: "monthlybilling" },
    DEMReport: { path: "DEMReport" }
  },

  detour: {
    path: "detour",
    // children
    cortex: { path: "cortex" },
    feza: { path: "feza" },
    esender: { path: "esender" }
  },

  error: {
    path: "error"
  },

  letterPreview: {
    path: "letterpreview",
    param: "subsampleId",
    letterId: {
      path: "letterid",
      param: "letterId",
    },
  },

  esenderCheck: {
    path: "esendercheck",
    param: "subsampleId",
    sifId: {
      param: "sifId"
    }

  },

  redirectError: {
    path: "redirectError"
  },

  event: {
    path: "event",
    // children
    id: {
      param: "eventId",
      // children
      subsampleList: { path: "subsamplelist" },
      costs: { path: "costs" },
      subsampleNew: { path: "subsamplenew" },
      surveyEventSettings: {
        path: "surveyeventsettings",
        // children
        generalSettings: { path: "generalsettings" },
        groupsTrackers: { path: "groupstrackers" },
        importSettings: { path: "importsettings" },
        projectTeam: { path: "projectteam" }
      }
    }
  },

  subsample: {
    path: "subsample",
    // children
    id: {
      param: "subsampleId",
      // children
      overview: {
        path: "overview"
      },
      quotas: {
        path: "quotas",
        // children
        sampleQuotas: { path: "samplequotas" },
        surveyQuotas: { path: "surveyquotas" },     
        quotaBuilder: { path: "quotabuilder" },
        profilingQuestions: { path: "profilingquestions"}
      },
      sampleBlend: {
        path: "sampleblend",
        //children
        //blend: { path: "blend" }
        //listOfSuppliers: { path: "listofsuppliers" },
        //resultsBySupplier: { path: "resultsBySupplier" }
      },
      internal: {
        path: "internal",
        // children
        sifList: { path: "sif-list" },
        sifStatus: {path: "sif-status"},
        sifUpload: {path: "sif-upload"},
        createMailout: {path: 'create-mailout'},
        mailoutDetails: {path:"mailout/:mailoutId"},
        id: { param: "sifId", paramNewSif: "new" },
        sifSettings: { path: "sif-settings" },
      },
      external: { path: "external" },
      costs: { path: "costs" },
      links: {
        path: "links",
        // children
        prescreenerSurvey: { path: "prescreenersurvey" },
        clientSurvey: { path: "clientsurvey" },
      },
      summary: { path: "summary" },
      feasibility: {
        path: "feasibility"
      },
      settings: {
        path: "settings",
        // children
        settings: { path: "settings" },
        sampleStrategy: { path: "samplestrategy" },
        eligibilityRules: { path: "eligibilityrules" },
        survey: { path: "survey" },
        sampleOptimization: { path: "sampleoptimization" },
        communicationSetup: {
          path: "communicationsetup",
          letterPreview: { path: "letterpreview" }
        },
        incentives: { path: "incentives" },
        importData: { path: "importdata" },
      },
    }
  },
  researchType: {
    path: "system/researchtype"
  }
};
