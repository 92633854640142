import {map} from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { AppSettings } from "../settings/app.settings";
import { HttpClientService } from "../utilities/http.client";
import { SubsampleModel } from "../models/subsample.model";
import { IEventModel } from "../models/event.model";
import { ISubsampleCountryModel } from "../models/subsample-country.model";
import { EventOrderbyEnum } from "../enums/event-orderby.enum";
import { ISubsampleModel } from "../models/subsample.model";
import { ISupplierModel } from "../models/supplier.model";
import { ISupplierUserModel } from "../models/supplier-user.model";
import { IDictionary } from "./../interfaces/dictionary.interface";
import { IRouterSurveyModel } from "../models/router-survey.model";

@Injectable()
export class EventService {
	private urls: IDictionary<string> = {
		"getEventList": this.appSettings.AdminApiPath + "Event/Dashboard",
		"getEventDetails": this.appSettings.AdminApiPath + "Event/{eventId}",
		"getEventCountries": this.appSettings.AdminApiPath + "Event/{eventId}/Countries",
		"updateSurveyEvent": this.appSettings.AdminApiPath + "Event",
		"createSubsample": this.appSettings.AdminApiPath + "Event/{eventId}/Subsample",
		"getSuppliers": this.appSettings.AdminApiPath + "Event/{eventId}/Suppliers",
    "getSupplierDefaultContacts": this.appSettings.AdminApiPath + "SupplierDefaultContacts",
    "checkIfEventExistsAndIsSampleOnly": this.appSettings.AdminApiPath + "CheckIfEventExistsAndIsSampleOnly/{eventName}",
    "checkIfEventHasLiveSurveys": this.appSettings.AdminApiPath + "CheckIfEventHasLiveSurveys/{eventName}",
    "getSurveyEventByEventName": this.appSettings.AdminApiPath + "GetSurveyEventByEventName/{eventName}",
    "getSurveyHealthByEvent": this.appSettings.AdminApiPath + "SurveyHealthByEvent/{eventId}",
	};

	constructor(
		private appSettings: AppSettings,
		private http: HttpClientService
	) { }

  private countries = null;

	getEventList = (page: number,
		itemsPerPage: number,
		filter?: string,
		adminUserIdFilter?: number,
    orderBy?: EventOrderbyEnum,
    billingStateFilter?: boolean): Observable<IEventModel[]> =>
		this.http.getSubscription(
			this.urls["getEventList"],
			{
				"pageNo": page || 1,
				"perPage": itemsPerPage || 5,
				"filter": filter || "",
				"adminUserIdFilter": adminUserIdFilter || -1,
        "orderBy": orderBy || EventOrderbyEnum.Name,
        "billingStateFilter": billingStateFilter || false
			}
		);

	getEventDetails = (eventId: number): Observable<IEventModel> =>
		this.http.getSubscription(this.urls["getEventDetails"], { "eventId": eventId || 0 });

	getEventCountries = (eventId: number): Promise<ISubsampleCountryModel[]> =>
		this.http
			.getSubscription(this.urls["getEventCountries"], { "eventId": eventId || 0 }).pipe(
			map((eventCountries: ISubsampleCountryModel[]) => {
				for (let c = 0, cl = eventCountries.length; c < cl; c++) {
					let subsamples = eventCountries[c].subsamples || [];
					for (let s = 0, sl = subsamples.length; s < sl; s++) {
						if (subsamples[s]) {
							subsamples[s] = new SubsampleModel(subsamples[s]);
						}
					}
        }
        this.countries = eventCountries;
				return eventCountries;
			}))
      .toPromise();

  getStoredEventCountries = (): ISubsampleCountryModel[] => {
    return this.countries;
  }

	updateSurveyEvent = (event: IEventModel): Promise<IEventModel> =>
		this.http.put(this.urls["updateSurveyEvent"], undefined, event);

	createSubsample = (eventId: number, subsample: ISubsampleModel): Observable<number> =>
		this.http.postSubscription(this.urls["createSubsample"], { "eventId": eventId || 0 }, subsample);

	getSuppliers = (eventId: number): Observable<ISupplierModel[]> =>
		this.http.getSubscription(this.urls["getSuppliers"], { "eventId": eventId || 0 });

	getSupplierDefaultContacts = (supplierId: number): Observable<ISupplierUserModel[]> =>
    this.http.getSubscription(this.urls["getSupplierDefaultContacts"], { "supplierId": supplierId || 0 });

  checkIfEventExistsAndIsSampleOnly = (eventName: string): Observable<boolean> =>
    this.http.getSubscription(this.urls["checkIfEventExistsAndIsSampleOnly"], { "eventName": eventName || "" });

  checkIfEventHasLiveSurveys = (surveyEventId: number): Observable<boolean> =>
    this.http.getSubscription(this.urls["checkIfEventHasLiveSurveys"], { "surveyEventId": surveyEventId || 0 });

  getSurveyEventByEventName = (eventName: string): Observable<IEventModel> =>
    this.http.getSubscription(this.urls["getSurveyEventByEventName"], { "eventName": eventName || "" });

  getSurveyHealthByEvent = (eventId: number): Observable<IRouterSurveyModel[]> =>
    this.http.getSubscription(this.urls["getSurveyHealthByEvent"], { "eventId": eventId || 0 });
}
