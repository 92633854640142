import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { HttpClientModule } from "@angular/common/http";

import { PopoverModule } from 'ngx-bootstrap/popover';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

/* App main */
import { AppComponent } from "./app.component";
import { AppRouting } from "./app.routing";

/* App components */
import { NavbarComponent } from "./ui/navbar.component";
import { FooterComponent } from "./ui/footer.component";
import { MenuLeftComponent } from "./ui/menu-left.component";
import { NotificationComponent } from "./ui/notification.component";

/* App shared components */
import { AppSharedModule } from "./shared-modules/app-shared.module";

/* Services */
import { AppContentStorageService } from "./services/app-content-storage.service";
import { AppSettings } from "./settings/app.settings";
import { AuthenticationService } from "./services/authentication.service";
import { LoadingAnimationService } from "./services/loading-animation.service";
import { HttpClientService } from "./utilities/http.client";
import { UserService } from "./services/user.service";
import { EventService } from "./services/event.service";
import { NotificationService } from "./services/notification.service";
import { EventStorageService } from "./services/event-storage.service";
import { UserStorageService } from "./services/user-storage.service";
import { HttpClient } from "@angular/common/http";
import { ADQuotaService } from "./services/ad-quota-builder.service";
import { ResearchTypeService } from "./services/research-type.service";

/* Pipes */
import { TrimPipe } from "./pipes/trim.pipe";
import { CapitalizePipe } from "./pipes/capitalize.pipe";
import { RoundPipe } from "./pipes/round.pipe";
import { CurrencySymbolPipe } from "./pipes/currency-symbol.pipe";
import { SupplierBillingStatePipe } from "./pipes/supplier-billing-state.pipe";
import { SupplierBillingStateDisableInputPipe } from "./pipes/supplier-billing-state-disable-input.pipe";
import { NumberSignPipe } from "./pipes/number-sign.pipe";
import { TextColorClassPipe } from "./pipes/text-color-class.pipe";
import { SupplierDiscountPercentPipe } from "./pipes/supplier-discount-percent.pipe";
import { SubsampleFieldLoiPipe } from "./pipes/subsample-field-loi.pipe";
import { EstimatedCPIColorPipe } from "./pipes/estimated-cpi-color.pipe";
import { SubsampleFieldIncidencePipe } from "./pipes/subsample-field-incidence.pipe";

/* Guards */
import { ReportsGuard } from "./guards/reports.guard";
import { TroubleshootingGuard } from "./guards/troubleshooting.guard";
import { RouterManagementGuard } from "./guards/router-management.guard";
import { AppRouteGuard } from "./guards/app.routing.guard";
import { AdminGuard } from "./guards/admin.guard";
import { QuotaBuilderGuard } from "./guards/quota-builder.guard";
import { SubsampleSummaryGuard } from "./guards/subsample-summary.guard";
import { FeasibilityGuard } from "./guards/feasibility-guard";
import { WorkFlowService } from "./services/workflow-service";
import { LockStatusGuard } from "./guards/lock-status.guard";
import { SubsampleStorageService } from "./services/subsample-storage.service";
import { SubsampleService } from "./services/subsample.service";
import { BlendGuard } from "./guards/blend.guard";
import { ManualFeasibilityGuard } from "./guards/manual-feasibility-guard";
import { DemReportingGuard } from "./guards/dem-reporting.guard";

@NgModule({
  imports: [
    PopoverModule.forRoot(),
    TooltipModule.forRoot(),
		BrowserModule,
		  AppRouting,
      HttpClientModule,
		AppSharedModule
	],
	declarations: [
		AppComponent,
		MenuLeftComponent,
		NotificationComponent,
		NavbarComponent,
		FooterComponent
	],
	providers: [
		AppContentStorageService,
    AppRouteGuard,
    AdminGuard,
		AppSettings,
		AuthenticationService,
		LoadingAnimationService,
		HttpClientService,
		EventService,
		UserService,
		NotificationService,
		EventStorageService,
		UserStorageService,
		TrimPipe,
    CapitalizePipe,
    RoundPipe,
    CurrencySymbolPipe,
    SupplierBillingStatePipe,
    SupplierBillingStateDisableInputPipe,
    NumberSignPipe,
    TextColorClassPipe,
    SupplierDiscountPercentPipe,
    SubsampleFieldLoiPipe,
    EstimatedCPIColorPipe,
    SubsampleFieldIncidencePipe,
    HttpClient,
    ReportsGuard,
    TroubleshootingGuard,
    RouterManagementGuard,
    SubsampleSummaryGuard,
    QuotaBuilderGuard,
    ADQuotaService,
    FeasibilityGuard,
    WorkFlowService,
    LockStatusGuard,
    SubsampleService,
    SubsampleStorageService,
    BlendGuard,
    ResearchTypeService,
    ManualFeasibilityGuard,
    DemReportingGuard
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
