import { Directive, ElementRef, HostListener, Input, Output, EventEmitter } from '@angular/core';
import { ICostControlSetEnum } from "./../models/cost-controls.model";

@Directive({
  selector: 'input[digits]'
})
export class DigitsDirective {

  @Input("setNumericInput") setNumericInput?: boolean = false;
  @Input("selectedSet") selectedSet: any;
  @Output() onNumericInputChange = new EventEmitter<any>();

  setEnum = ICostControlSetEnum;

  defaultDigitOptions = {
    "CPI": 2,
    "MIN_FEE": 3,
    "OTHER_FEE": 3,
    "DISCOUNT": 3
  }

  constructor(private elRef: ElementRef) { }

  @HostListener('input', ['$event']) onInputChange(event) {

    event.target.value = event.target.value.replace(",", "."); //some may mistake , for . when adding decimals
    event.target.value = event.target.value.replace(/[^\d,.]+/g, ''); //allow only numbers
    var hasDecimals = (event.target.value - Math.floor(event.target.value)) !== 0;
    if (!hasDecimals) return;

    let source = event.target.getAttribute('data-source');
    let allowedDecimals = this.getDefaultDigitsForSelectedDropdown(source);
    let actualNrOfDecimal = this.countDecimals(event.target.value);
    if (actualNrOfDecimal > allowedDecimals) {
      event.target.value = event.target.value.slice(0, event.target.value.length - (actualNrOfDecimal - allowedDecimals));
      if (this.setNumericInput) this.onNumericInputChange.emit(event.target.value);
    }
      
  }

  countDecimals = function (value) {
    if (Math.floor(value) !== value)
      return value.toString().split(".")[1].length || 0;
    return 0;
  }

  getDefaultDigitsForSelectedDropdown(source?: string): number {
    let selectSource = source == null ? this.selectedSet.selected : this.setEnum[source];
    let decimals = 2;
    switch (selectSource) {
      case (this.setEnum["CPI"]): {
        decimals = this.defaultDigitOptions["CPI"];
        break;
      }
      case (this.setEnum["Minimum Fee"]): {
        decimals = this.defaultDigitOptions["MIN_FEE"];

        break;
      }
      case (this.setEnum["Other Fees"]): {
        decimals = this.defaultDigitOptions["OTHER_FEE"];
        break;
      }
      case (this.setEnum["Discount"]): {
        decimals = this.defaultDigitOptions["DISCOUNT"];
        break;
      }
    }
    return decimals;
  }

}
