
import {map} from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { AppSettings } from "../settings/app.settings";
import { HttpClientService } from "../utilities/http.client";
import { ISubsampleModel } from "../models/subsample.model";
import { ISampleCategory } from "../models/sample-category.model";
import { SurveyStatusEnum } from "../enums/survey-status.enum";
import { SubsampleModel } from "../models/subsample.model";
import { ISubsampleStatusValidationsDataModel } from "../models/subsample-status-validations-data.model";
import { ISurveyParam } from "../models/survey-param.model";
import { IIpsReportingVariable } from "../models/ips-reporting-variable.model";
import { ISubsampleCopyItems, SubsampleCopyItems } from "../models/subsample-copy-items.model";
import { IDictionary } from "./../interfaces/dictionary.interface";
import { IErrorDetails } from '../models/error-details.model';
import { ISurveyProfilingQuestionsModel } from '../models/survey-profiling-questions.model';
import { ISubsampleDailyTargetsModel } from '../models/subsample-daily-targets.model';

@Injectable()
export class SubsampleService {
	constructor(
		private appSettings: AppSettings,
        private http: HttpClientService
	) { }

  private urls: IDictionary<any> = {
    "getSubsampleDetails": this.appSettings.AdminApiPath + "Subsample/{subsampleId}",
    "saveSubsampleDetails": this.appSettings.AdminApiPath + "Subsample",
    "getSampleCategorySubcategoryAll": this.appSettings.AdminApiPath + "SampleCategorySubcategoryAll",
    "updateSubsampleStatus": this.appSettings.AdminApiPath + "Subsample/{subsampleId}",
    "getSubsampleStatusValidationsData": this.appSettings.AdminApiPath + "SubsampleStatusValidationsData",
    "getSurveyParams": this.appSettings.AdminApiPath + "SurveyParams/{subsampleId}",
    "saveSurveyParams": this.appSettings.AdminApiPath + "SurveyParams/{subsampleId}",
    "saveSurveyReportingVariables": this.appSettings.AdminApiPath + "SurveyReportingVariables/{subsampleId}",
    "getSurveyReportingVariables": this.appSettings.AdminApiPath + "SurveyReportingVariables/{subsampleId}",
    "getPredefinedSurveyParamList": this.appSettings.AdminApiPath + "GetPredefinedSurveyParamList",
    "tryApplyTemplates": this.appSettings.AdminApiPath + "SurveyApplyTemplates/{subsampleId}",
    "getOverwriteWarnings": this.appSettings.AdminApiPath + "SubsampleCopy/OverwriteWarnings",
    "copySubsampleSettings": this.appSettings.AdminApiPath + "SubsampleCopy/ImportSettings",
    "hasRealTraffic": this.appSettings.AdminApiPath + "Subsample/HasRealTraffic",
    "HasIncentiveGridAssignedToSif": this.appSettings.AdminApiPath + "Subsample/hasIncentiveGridAssignedToSif",
    "saveSubsampleAutoIncrementSchedule": this.appSettings.AdminApiPath + "SubsampleAutoIncrementSchedule",
    "getSupplierAllocatedPercent": this.appSettings.AdminApiPath + "Subsample/SupplierAllocatedPercent",
    "saveSampleQuotaMode": this.appSettings.AdminApiPath + "SaveSampleQuotaMode",
    "getSubsamplesByCountry": this.appSettings.AdminApiPath + "GetSubsamplesByCountry/{countryId}",
    "getp1ReportedErrors": this.appSettings.AdminApiPath + "Subsample/Errors/{date}",
    "getDisableCreateSubsampleWhenStatusLocked": this.appSettings.AdminApiPath + "GetDisableCreateSubsampleWhenStatusLocked",
    "updateSubsampleStatusLocked": this.appSettings.AdminApiPath + "UpdateSubsampleStatusLocked/{subsampleId}/{lockedFlag}/{lockedFromUI}",
    "updateSubsampleExemptedFromBeingPausedOrLockedFlag": this.appSettings.AdminApiPath + "UpdateSubsampleExemptedFromBeingPausedOrLocked/{subsampleId}/{exemptedFromBeingPausedOrLockedFlag}",
    "getSubsamplesExemptedFromBeingPausedOrLocked": this.appSettings.AdminApiPath + "GetSubsamplesExemptedFromBeingPausedOrLocked",
    "checkIfSurveyExists": this.appSettings.AdminApiPath + "CheckIfSurveyExists/{subsampleId}",
    "getSubsampleStatusAndStatusLockedFlag": this.appSettings.AdminApiPath + "GetSubsampleStatusAndStatusLockedFlag/{subsampleId}",
    "saveSurveyProfilingQuestions": this.appSettings.AdminApiPath + "SaveProfilingQuestions/{subsampleId}",
    "getSurveyProfilingQuestions": this.appSettings.AdminApiPath + "GetProfilingQuestions/{subsampleId}",
    "getDailyTargets": this.appSettings.AdminApiPath + "GetDailyTargets/{subsampleId}",
  };

	getSubsampleDetails = (subsampleId: number): Observable<ISubsampleModel> =>
		this.http
			.getSubscription(this.urls["getSubsampleDetails"], { "subsampleId": subsampleId }).pipe(
			map((subsample: ISubsampleModel) => new SubsampleModel(subsample)));

	saveSubsampleDetails = (subsample: ISubsampleModel): Promise<ISubsampleModel> => {
		return this.http.post(this.urls["saveSubsampleDetails"], undefined, subsample);
	}

  saveSampleQuotaMode = (subsampleId: number, sampleQuotaMode: number): Observable<void> =>
  this.http.putSubscription(this.urls["saveSampleQuotaMode"], { "subsampleId": subsampleId, "quotaMode": sampleQuotaMode });

	getSampleCategorySubcategoryAll = (): Promise<ISampleCategory[]> =>
		this.http.get(this.urls["getSampleCategorySubcategoryAll"], { });

	updateSubsampleStatus = (subsampleId: number, status: SurveyStatusEnum): Promise<boolean> =>
		this.http.put(this.urls["updateSubsampleStatus"], { "subsampleId": subsampleId }, status);

  getSubsampleStatusValidationsData = (subsampleIdList: number[]): Observable<ISubsampleStatusValidationsDataModel> =>
        this.http.postSubscription(this.urls["getSubsampleStatusValidationsData"], {}, subsampleIdList );

	getSurveyParams = (subsampleId: number): Observable<ISurveyParam[]> =>
	    this.http.getSubscription(this.urls["getSurveyParams"], { "subsampleId": subsampleId || 0 });

	saveSurveyParams = (subsampleId: number, paramList: ISurveyParam[]): Observable<boolean> =>
	    this.http.postSubscription(this.urls["saveSurveyParams"], { "subsampleId": subsampleId || 0 }, paramList);

	getSurveyReportingVariables = (subsampleId: number): Observable<IIpsReportingVariable[]> =>
	    this.http.getSubscription(this.urls["getSurveyReportingVariables"], { "subsampleId": subsampleId || 0 });

	saveSurveyReportingVariables = (subsampleId: number, variablesList: IIpsReportingVariable[]): Observable<boolean> =>
		this.http.postSubscription(this.urls["saveSurveyReportingVariables"], { "subsampleId": subsampleId || 0 }, variablesList);

	getPredefinedSurveyParamList = (): Observable<ISurveyParam[]> =>
		this.http.getSubscription(this.urls["getPredefinedSurveyParamList"], {});

	tryApplyTemplates = (subsampleId: number): Observable<boolean> =>
		this.http.postSubscription(this.urls["tryApplyTemplates"], { "subsampleId": subsampleId || 0 });

	getOverwriteWarnings = (subsampleId: number, subsampleCopyItems: ISubsampleCopyItems): Observable<ISubsampleCopyItems> =>
		this.http
			.getSubscription(this.urls["getOverwriteWarnings"], {
				"subsampleId": subsampleId,
				"subsampleCopyItems.sampleQuotas": subsampleCopyItems.sampleQuotas || false,
				"subsampleCopyItems.surveyQuotas": subsampleCopyItems.surveyQuotas || false,
        "subsampleCopyItems.reportingVariables": subsampleCopyItems.reportingVariables || false,
        "subsampleCopyItems.profilingQuestions": subsampleCopyItems.profilingQuestions || false,
				"subsampleCopyItems.sampleBlend": subsampleCopyItems.sampleBlend || false,
				"subsampleCopyItems.subsampleSetting": subsampleCopyItems.subsampleSetting || false,
				"subsampleCopyItems.eligibilityRules": subsampleCopyItems.eligibilityRules || false,
				"subsampleCopyItems.surveyParameters": subsampleCopyItems.surveyParameters || false,
				"subsampleCopyItems.sampleOptimizationSettings": subsampleCopyItems.sampleOptimizationSettings || false,
				"subsampleCopyItems.communicationSetup": subsampleCopyItems.communicationSetup ||false
			}).pipe(
			map((subsampleCopyItem: ISubsampleCopyItems) => new SubsampleCopyItems(subsampleCopyItem)));

	copySubsampleSettings = (sourceSubsampleId: number, destinationSubsampleId: number, subsampleCopyItems: ISubsampleCopyItems): Observable<boolean> =>
		this.http
			.getSubscription(this.urls["copySubsampleSettings"], {
				"sourceSubsampleId": sourceSubsampleId,
				"destinationSubsampleId": destinationSubsampleId,
				"subsampleCopyItems.sampleQuotas": subsampleCopyItems.sampleQuotas || false,
				"subsampleCopyItems.surveyQuotas": subsampleCopyItems.surveyQuotas || false,
        "subsampleCopyItems.reportingVariables": subsampleCopyItems.reportingVariables || false,
        "subsampleCopyItems.profilingQuestions": subsampleCopyItems.profilingQuestions || false,
				"subsampleCopyItems.sampleBlend": subsampleCopyItems.sampleBlend || false,
				"subsampleCopyItems.subsampleSetting": subsampleCopyItems.subsampleSetting || false,
				"subsampleCopyItems.eligibilityRules": subsampleCopyItems.eligibilityRules || false,
				"subsampleCopyItems.surveyParameters": subsampleCopyItems.surveyParameters || false,
				"subsampleCopyItems.sampleOptimizationSettings": subsampleCopyItems.sampleOptimizationSettings || false,
				"subsampleCopyItems.communicationSetup": subsampleCopyItems.communicationSetup || false
			});

	hasRealTraffic = (subsampleId: number): Observable<boolean> =>
		this.http.getSubscription(this.urls["hasRealTraffic"], { "subsampleId": subsampleId || 0 });

	HasIncentiveGridAssignedToSif = (sifNumber: string): Observable<boolean> =>
		this.http.getSubscription(this.urls["HasIncentiveGridAssignedToSif"], { "sifNumber": sifNumber || "0" });

	saveSubsampleAutoIncrementSchedule = (subsample: ISubsampleModel): Observable<boolean> =>
        this.http.postSubscription(this.urls["saveSubsampleAutoIncrementSchedule"], {}, subsample);

    getSupplierAllocatedPercent = (subsampleId: number, supplierId: number, isGroup: boolean): Observable<number> =>
    this.http.getSubscription(this.urls["getSupplierAllocatedPercent"], { "subsampleId": subsampleId || 0, "supplierId": supplierId || 0, "isGroup": isGroup || false });

  getSubsamplesByCountry(countryId: number): Observable<number[]> {
    return this.http.getSubscription(this.urls["getSubsamplesByCountry"], { countryId: countryId });
  }

  getp1ReportedErrors = (date: string, subsampleIds: number[]): Observable<IErrorDetails[]> =>
    this.http.postSubscription(this.urls["getp1ReportedErrors"], { "date": date}, subsampleIds);

  getDisableCreateSubsampleWhenStatusLocked = (): Observable<boolean> =>
    this.http.getSubscription(this.urls["getDisableCreateSubsampleWhenStatusLocked"], {});


  updateSubsampleStatusLocked = (subsampleId: number, statusLocked: boolean, lockedFromUI: boolean): Promise<boolean> =>
    this.http.put(this.urls["updateSubsampleStatusLocked"], {
      "subsampleId": subsampleId, "lockedFlag": statusLocked, "lockedFromUI": lockedFromUI
    });

  updateSubsampleExemptedFromBeingPausedOrLockedFlag = (subsampleId: number, exemptedFromBeingPausedOrLockedFlag: boolean): Promise<boolean> =>
    this.http.put(this.urls["updateSubsampleExemptedFromBeingPausedOrLockedFlag"], {
      "subsampleId": subsampleId, "exemptedFromBeingPausedOrLockedFlag": exemptedFromBeingPausedOrLockedFlag
    });

  getSubsamplesExemptedFromBeingPausedOrLocked(): Observable<number[]> {
    return this.http.getSubscription(this.urls["getSubsamplesExemptedFromBeingPausedOrLocked"], { });
  }

  checkIfSurveyExists = (subsampleId: number): Observable<boolean> =>
    this.http.getSubscription(this.urls["checkIfSurveyExists"], { "subsampleId": subsampleId || 0 });

  getSubsampleStatusAndStatusLockedFlag = (subsampleId: number): Observable<any> =>
    this.http.getSubscription(this.urls["getSubsampleStatusAndStatusLockedFlag"], { "subsampleId": subsampleId || 0 });

  saveSurveyProfilingQuestions = (subsampleId: number, variablesList: ISurveyProfilingQuestionsModel[]): Observable<boolean> =>
    this.http.postSubscription(this.urls["saveSurveyProfilingQuestions"], { "subsampleId": subsampleId || 0 }, variablesList);

  getSurveyProfilingQuestions = (subsampleId: number): Observable<ISurveyProfilingQuestionsModel[]> =>
    this.http.getSubscription(this.urls["getSurveyProfilingQuestions"], { "subsampleId": subsampleId || 0 });

  getDailyTargets = (subsampleId: number): Observable<ISubsampleDailyTargetsModel[]> =>
    this.http.getSubscription(this.urls["getDailyTargets"], { "subsampleId": subsampleId || 0 });
 }
