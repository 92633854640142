// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  accessToken: "accessToken",
  userData: "userData",
  userType: "userType",
  authenticateTokenURI: "//cortexapi5qagcp.ipsos.com/token",
  quotasRedirectPagePath: "//sampleoneqa.ipsos.com/v1/SubsampleQuotas/Index/{eventId}/{subsampleId}?cid={countryId}&lid={languageId}&embed=true",
  overviewRedirectPagePath: "//sampleoneqa.ipsos.com/v1/SubsampleOverview/Index/{eventId}/{subsampleId}?embed=true",
  apiPath: "//cortexapi5qagcp.ipsos.com/",
  adminApiPath: "//cortexapi5qagcp.ipsos.com/admin/",
  ipsApiPath: "//cortexapi5qagcp.ipsos.com/IPS/",
  commonApiPath: "//cortexapi5qagcp.ipsos.com/common/1/",
  audienceDesignerApiPath: "//cortexapi5qagcp.ipsos.com/audience-designer/1/",
  workflowPath: "//cortexapi5qagcp.ipsos.com/workflow/",
  employeeImage: "//cortexadmin5qagcp.ipsos.com/svc/DomainImage.ashx?i={imagePath}",
  defaultEmployeeImage: "//cortexadmin5qagcp.ipsos.com/svc/DomainImage.ashx?i=https://employeedirectory.ipsos.com/Images/NoPhotoFound.jpg",
  redirectorPath: "//cortexadmin5qagcp.ipsos.com/osm/Redirector.aspx?empid={employeeId}&sid={surveyId}&loc={location}&IdJob={jobId}&hash={hash}&notimeout={notimeout}",
  fezaPath: "//fizzyuiqa.ipsosinteractive.com/#/s1response?event={eventId}&bidId={bidId}",
  fezaApiPath: "//fizzyqa.ipsosinteractive.com",
  adminEmailHelpAddress: "s1.support@ipsos.com",
  accessGroup: "access",
  scriptingGroup: "iis-scripting",
  superUserGroup: "SuperUser",
  adminGroups: ["RAES"],
  reportGroups: ["iis-finance"],
  qualityGroup: "IIS Quality",
  troubleshootingGroup: "Troubleshooting",
  routerManagementGroup: "Router Management",
  feasibilityGroup: "Feasibility",
  lockUnlockStatusGroup: "Lock/Unlock Status Group",
  manualFeasibilityGroup: "Manual Feasibility",
  demReportingGroup: "DEM Reporting",
  env: 'qagcp'
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
