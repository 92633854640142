
export class StringExtensions {

  public static jsonParse(text: string) {
    try {
      return JSON.parse(text);
    } catch (ex) {
      return {};
    }
  }

  public static jsonQueryString(json, separator = "&", keyValueSeparator = "="): string {
    let text = "";
    for (let p in json) {
      if (json.hasOwnProperty(p)) {
        text += (text.length === 0 ? "" : (separator || "&")) + p + (keyValueSeparator || "=") + json[p];
      }
    }
    return text;
  }

  public static generateUUID(): string {
    let seed = new Date().getTime();
    if (window.performance && typeof window.performance.now === "function") {
      seed += performance.now(); // use high-precision timer if available
    }
    let uuid: string = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (char: string): string {
        /*jshint bitwise: false*/
        let r = (seed + Math.random() * 16) % 16 | 0;
        seed = Math.floor(seed / 16);
        return (char === "x" ? r : (r & 0x3 | 0x8)).toString(16);
        /*jshint bitwise: true*/
      }
    );
    return uuid;
  }
}
