import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable()
export class LoadingAnimationService {
	private loaderSubject = new Subject<boolean>();
	private showCount = 0;

	loaderSubscription = this.loaderSubject.asObservable();

	show() {
		this.showCount += 1;
		if (this.showCount === 1) { this.loaderSubject.next(true); }
	}

	hide() {
		if (this.showCount > 0) { this.showCount -= 1; }
		if (this.showCount === 0) { this.loaderSubject.next(false); }
	}
}
