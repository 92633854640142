import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { PageNotFoundComponent } from "../components/page-not-found.component";
import { EventHeaderComponent } from "../ui/event-header/event-header.component";
import { DebounceDirective } from "../directives/debounce.directive";
import { DigitsDirective } from "../directives/digits.directive";
import { IntegerDirective } from "../directives/integer.directive";

let sharedComponents: Array<any> = [
	PageNotFoundComponent,
	EventHeaderComponent,
  DebounceDirective,
  DigitsDirective,
  IntegerDirective
];

@NgModule({
	imports: [CommonModule],
	declarations: sharedComponents,
	exports: sharedComponents,
})
export class AppSharedModule { }
