import { ModuleWithProviders } from "@angular/core";
import { Routes, RouterModule, PreloadAllModules } from "@angular/router";

import { AppRoutes } from "./settings/routes";
import { PageNotFoundComponent } from "./components/page-not-found.component";
import { AppRouteGuard } from "./guards/app.routing.guard";
import { AdminGuard } from "./guards/admin.guard";
import { RouterManagementGuard } from "./guards/router-management.guard";
import { TroubleshootingGuard } from "./guards/troubleshooting.guard";
import { ReportsGuard } from "./guards/reports.guard";


const p = "./../app/ui/pages/";
const routes: Routes = [
	{
		path: "", pathMatch: "full",
		redirectTo: AppRoutes.dashboard.path
	},
	{
		path: AppRoutes.login.path,
		loadChildren: `${p}login/login.module#LoginModule`,
		canActivate: [AppRouteGuard]
	},
	{
		path: AppRoutes.login.path + "/",
		loadChildren: `${p}login/login.module#LoginModule`,
		canActivate: [AppRouteGuard],

	},
	{
		path: AppRoutes.dashboard.path,
		loadChildren: `${p}dashboard/dashboard.module#DashboardModule`,
		canActivate: [AppRouteGuard]
  },
  {
    path: AppRoutes.tools.path,
    loadChildren: `${p}tools/tools.module#ToolsModule`,
    canActivate: [AppRouteGuard]
  },
  {
    path: AppRoutes.systemSettings.path,
    loadChildren: `${p}system-settings/system-settings.module#SystemSettingsModule`,
    canActivate: [AdminGuard]
  },
  {
    path: AppRoutes.routerManagement.path,
    loadChildren: `${p}router-management/router-management.module#RouterManagementModule`,
    canActivate: [RouterManagementGuard]
  },
  {
    path: AppRoutes.troubleshooting.path,
    loadChildren: `${p}troubleshooting/troubleshooting.module#TroubleshootingModule`,
    canActivate: [TroubleshootingGuard]
  },
  {
    path: AppRoutes.reports.path,
    loadChildren: `${p}reports/reports.module#ReportsModule`,
    canActivate: [AppRouteGuard]
  },
	{
		path: AppRoutes.event.path,
		loadChildren: `${p}event/event.module#EventModule`,
		canActivate: [AppRouteGuard]
	},
	{
		path: AppRoutes.subsample.path,
		loadChildren: `${p}subsample/subsample.module#SubsampleModule`,
		canActivate: [AppRouteGuard]
	},
	{
		path: AppRoutes.detour.path,
		loadChildren: `${p}detour/detour-loading.module#DetourLoadingModule`,
		canActivate: [AppRouteGuard]
	},
	{
		path: AppRoutes.error.path,
		loadChildren: `${p}error/page-error.module#PageErrorModule`,
		canActivate: [AppRouteGuard]
    },
    {
        path: AppRoutes.esenderCheck.path + "/:" +
        AppRoutes.esenderCheck.param + "/:" +
        AppRoutes.esenderCheck.sifId.param,
        loadChildren: `${p}esender-check/esender-check.module#EsenderCheckModule`,
        canActivate: [AppRouteGuard]
    },
	{
		path: AppRoutes.letterPreview.path + "/:" +
			AppRoutes.letterPreview.param + "/" +
			AppRoutes.letterPreview.letterId.path + "/:" +
			AppRoutes.letterPreview.letterId.param,
		loadChildren: `${p}letter-preview/letter-preview.module#LetterPreviewModule`,
		canActivate: [AppRouteGuard]
	},
	{
	    path: AppRoutes.redirectError.path,
	    loadChildren: `${p}error/redirect-error.module#RedirectErrorModule`,
	    canActivate: [AppRouteGuard]
    },
    {
        path: AppRoutes.researchType.path,
        loadChildren: `${p}system/research-type/research-type.module#ResearchTypeModule`,
        canActivate: [AppRouteGuard]
    },
	{
		path: "**",
		component: PageNotFoundComponent
	}
];

export const AppRouting: ModuleWithProviders = RouterModule.forRoot(routes, { useHash: true });
