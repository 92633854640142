import { Pipe, PipeTransform } from '@angular/core';
import { ISubsampleCostItem } from "../models/subsample-cost-item.model";
import { BillingStateEnum } from "../enums/billing-state.enum";

@Pipe({ name: 'supplierBillingStateDisableInput' })
export class SupplierBillingStateDisableInputPipe implements PipeTransform {
  transform(value?: ISubsampleCostItem[], trigger?: number): boolean {

    //if no subsamples
    if (value.length == 0)
      return false;
    //check if at least one subsample is in "Not ready.."
    if (value.some(subsample => subsample.billingState == BillingStateEnum.NotReadyForCostPublish))
      return false;

    //check if at least one subsample is in AwaitingSupplierApproval
    if (value.some(subsample => subsample.billingState == BillingStateEnum.AwaitingSupplierApproval))
      return true;

    //check if at least one subsample is in Finalized
    if (value.some(subsample => subsample.billingState == BillingStateEnum.Finalized))
      return true;
  
    return false;
  }
}
