import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'estimatedCPIColor', pure: true })
export class EstimatedCPIColorPipe implements PipeTransform {

  transform(cpiFromRateCard: number, expectedCPI: number, estimatedCPIColorPercentages: number[]): string {
    let result,
      cpiFromRateCardLow = cpiFromRateCard * (1-estimatedCPIColorPercentages[0] / 100),
      cpiFromRateCardHigh = cpiFromRateCard * (1-estimatedCPIColorPercentages[1] / 100);

    if (expectedCPI <= cpiFromRateCardLow)
      result = "text-success"
    else if (cpiFromRateCardLow < expectedCPI && expectedCPI < cpiFromRateCardHigh)
      result = "text-warning";
    else if (expectedCPI >= cpiFromRateCardHigh)
      result = "text-danger";

    return result;
  }

}
