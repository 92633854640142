import { Injectable } from "@angular/core";
import { environment } from '../../environments/environment';
import { IDictionary } from "src/app/interfaces/dictionary.interface";

@Injectable()
export class AppSettings {
  get CookieAuthToken(): string { return environment.accessToken }
  get CookieUserData(): string { return environment.userData }
  get CookieUserType(): string { return environment.userType }
  get AuthenticateTokenURI(): string { return environment.authenticateTokenURI }
  get QuotasRedirectPagePath(): string { return environment.quotasRedirectPagePath }
  get OverviewRedirectPagePath(): string { return environment.overviewRedirectPagePath }
  get ApiPath(): string { return environment.apiPath }
  get AdminApiPath(): string { return environment.adminApiPath }
  get IPSApiPath(): string { return environment.ipsApiPath }
  get CommonApiPath(): string { return environment.commonApiPath }
  get AudienceDesignerApiPath(): string { return environment.audienceDesignerApiPath }
  get EmployeeImage(): string { return environment.employeeImage }
  get DefaultEmployeeImage(): string { return environment.defaultEmployeeImage }
  get AdminEmailHelpAddress(): string { return environment.adminEmailHelpAddress }
  get RedirectorPath(): string { return environment.redirectorPath }
  get FezaPath(): string { return environment.fezaPath }
  get FezaApiPath(): string { return environment.fezaApiPath }
  get AccessGroup(): string { return environment.accessGroup }
  get ScriptingGroup(): string { return environment.scriptingGroup }
  get SuperUserGroup(): string { return environment.superUserGroup }
  get IISQualityGroup(): string { return environment.qualityGroup} 
  get AdminGroups(): string[] { return environment.adminGroups }
  get ReportGroups(): string[] { return environment.reportGroups }
  get TroubleshootingGroup(): string { return environment.troubleshootingGroup }
  get RouterManagementGroup(): string { return environment.routerManagementGroup }
  get FeasibilityGroup(): string { return environment.feasibilityGroup }
  get MinimumDate(): Date { return new Date("1900-01-01T00:00:00"); }
  get MaximumDate(): Date { return new Date("9999-12-31T23:59:59.9999999"); }
  get WorkFlowAPIPath(): string { return environment.workflowPath }
  get LockUnlockStatusGroup(): string { return environment.lockUnlockStatusGroup }
  get ManualFeasibilityGroup(): string { return environment.manualFeasibilityGroup }
  get DemReportingGroup(): string { return environment.demReportingGroup } 

  firstTimeLoad = false;
}
