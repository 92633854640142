import { Injectable } from "@angular/core";
import { CanActivate } from "@angular/router";
import { IUserCookieModel } from "../models/user-cookie.model";
import { AppContentStorageService } from "../services/app-content-storage.service";
import { UserStorageService } from "../services/user-storage.service";
import { AppSettings } from "../settings/app.settings";
import { AppRouteGuard } from "./app.routing.guard";
import { Observable } from "rxjs/internal/Observable";

@Injectable()
export class AdminGuard extends AppRouteGuard implements CanActivate {

  private appGroups: string[] = [];
  userData: IUserCookieModel;

  constructor(private appaContentStorageService: AppContentStorageService,
    private appSettings: AppSettings,
    private userStorageService: UserStorageService) {
    super(appaContentStorageService);
    this.appGroups = appSettings.AdminGroups;
  }

  canActivate(): Observable<boolean> {
    return new Observable((result) => {
      if (this.userStorageService.user && this.userStorageService.user.groups.length > 0) {
        result.next(this.checkUserGroups(this.userStorageService.user, this.appGroups));
      } else {
        this.userStorageService.userSubscription.subscribe(user => {
          result.next(this.checkUserGroups(user, this.appGroups));
        });
      }
    });
  }  
}
