import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable()
export class AppContentStorageService {
	private headerSubject = new Subject<boolean>();
	private menuSubject = new Subject<boolean>();
	private notificationSubject = new Subject<boolean>();
	private loaderSubject = new Subject<boolean>();
	private footerSubject = new Subject<boolean>();

	headerSubscription = this.headerSubject.asObservable();
	menuSubscription = this.menuSubject.asObservable();
	notificationSubscription = this.notificationSubject.asObservable();
	loaderSubscription = this.loaderSubject.asObservable();
	footerSubscription = this.footerSubject.asObservable();

	headerVisibility(state: boolean) { this.headerSubject.next(!!state); }
	menuVisibility(state: boolean) { this.menuSubject.next(!!state); }
	notificationVisibility(state: boolean) { this.notificationSubject.next(!!state); }
	loaderVisibility(state: boolean) { this.loaderSubject.next(!!state); }
	footerVisibility(state: boolean) { this.footerSubject.next(!!state); }
}
