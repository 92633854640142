import { ICurrencyItemModel } from "../models/currency-item.model";

export interface ICostControls {
  suppliers: ICostControlsSupplier[],
  editMode: boolean
}

export interface ICostControlsSupplier {
  set: number,
  status: string,
  costIncidence: number,
  LOI: number,
  CPI: number,
  CPIAPI: number,
  minimumFee: number,
  extraFees: number,
  discount: number,
  on: number,
  supplierId: number,
  subsamples: ICostControlsSubsample[],
  visibility: boolean,
  filteredIn: boolean,
  hasDiscountValidationErrors: boolean,
  hasDiscountReasonOversize: boolean,
  filteredInBySubsampleNumber: boolean,
  hasOtherFeesReasonValidationErrors : boolean
}

export interface ICostControlsSubsample {
  checkbox: boolean,
  visibility: boolean,
  filteredIn: boolean,
  tempered: boolean,
  subsampleId: number,
  hasMaxCpiValidationErrors: boolean,
  maxCpiDisplay:number,
  subsamplesCpi: ICostControlsSubsampleCpi[],
  hasForceSameCpiValidationErrors: boolean,
  startoffCPIDeviationUse: number,
  maxCPIDeviationUse:number,
  maxCPIUse: number,
  maxCpiCurrency?: ICurrencyItemModel,
  surveyUsesAutomatedBlend: boolean,
  stopAutomatedBlendFlag: boolean,
  useAutomatedBlendOptionId: number
}

export interface ICostControlsSubsampleCpi {
  cpiNumber: number,
  enabled: boolean,
  visibility: boolean
}


export enum ICostControlSetEnum {
  "CPI" = 1,
  "Action" = 2,
  "Minimum Fee" = 3,
  "Other Fees" = 4
}

export enum IScopeOfChangesEnum {
    "Selected Subsamples" = 1,
    "All Subsamples" = 2
}

export enum ICpiAllowedOptions {
  "One CPI per project for all completes" = 1,
  "Dynamic CPI only for API projects (one CPI for non API projects)" = 2,
  "Dynamic CPI only for Marketplace projects(one CPI for ALL API projects)" = 3,
  "Only Dynamic CPI" = 4
}
