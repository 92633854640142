import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { IUserCookieModel } from "../models/user-cookie.model";
import { AppContentStorageService } from "../services/app-content-storage.service";
import { AppRouteGuard } from "./app.routing.guard";
import { Observable } from "rxjs/internal/Observable";
import { AppRoutes } from "../settings/routes";

@Injectable()
export class BlendGuard extends AppRouteGuard implements CanActivate {

  userData: IUserCookieModel;

  constructor(private appaContentStorageService: AppContentStorageService,
    private router: Router  ) {
    super(appaContentStorageService);
  }

  canActivate(): Observable<boolean> {
    return new Observable((result) => {
          result.next(true);
    });
  }

  redirect() {
    var surveyId = this.router.url.match(/\d+/)[0];
    this.router.navigate([AppRoutes.subsample.path, surveyId, AppRoutes.subsample.id.sampleBlend.path]); 
  }
}
