import { Injectable } from "@angular/core";
import { CanActivate } from "@angular/router";
import { Observable } from "rxjs";
import { IUserCookieModel } from "../models/user-cookie.model";
import { AppContentStorageService } from "../services/app-content-storage.service";
import { UserStorageService } from "../services/user-storage.service";
import { AppSettings } from "../settings/app.settings";
import { AppRouteGuard } from "./app.routing.guard";

@Injectable()
export class SubsampleSummaryGuard extends AppRouteGuard implements CanActivate {

  private routerManagementGroup: string;
  private troubleshootGroup: string;
  userData: IUserCookieModel;
  userGroups: string[];

  constructor(private appaContentStorageService: AppContentStorageService,
    private appSettings: AppSettings,
    private userStorageService: UserStorageService) {
    super(appaContentStorageService);
    this.routerManagementGroup = this.appSettings.RouterManagementGroup;
    this.troubleshootGroup = this.appSettings.TroubleshootingGroup;
  }

  canActivate(): Observable<boolean> {
    return new Observable((result) => {
      if (this.userStorageService.user && this.userStorageService.user.groups.length > 0) {
        result.next(true);
      } else {
        this.userStorageService.userSubscription.subscribe(user => {
          result.next(true);
        });
      }
    });
  }
}

