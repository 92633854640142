import { Component } from "@angular/core";

@Component({
	selector: "page-not-found",
	template: `
	<div class="container margin-top-md">
		<div class="jumbotron">
			<h1>404</h1>
			<p>The requested resource was not found</p>
		</div>
	</div>
` })
export class PageNotFoundComponent { }
