import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { AppSettings } from "../settings/app.settings";
import { HttpClientService } from "../utilities/http.client";
import { IDictionary } from "./../interfaces/dictionary.interface";
import { IWorkflowEvent } from '../models/workflow-event';
import { ISubsampleModel } from "../models/subsample.model";


@Injectable()

export class WorkFlowService {
  private urls: IDictionary<string> = {
    "createEvent": this.appSettings.WorkFlowAPIPath + "Event",
    "createSubsample": this.appSettings.WorkFlowAPIPath + "SubsampleFromS1"
  };


  constructor(
    private appSettings: AppSettings,
    private http: HttpClientService
  ) { }


  createEvent = (event: IWorkflowEvent): Observable<number> =>
    this.http.postSubscription(this.urls["createEvent"], {}, event);

  createSubsample = (eventId: number, subsample: ISubsampleModel): Observable<number> =>
    this.http.postSubscription(this.urls["createSubsample"], { "eventId": eventId }, subsample);
}

