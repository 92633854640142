import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate } from "@angular/router";
import { Observable } from "rxjs/internal/Observable";
import { AppContentStorageService } from "../services/app-content-storage.service";
import { AppRoutes } from "../settings/routes";
import { IUserModel } from "../models/user.model";

@Injectable()
export class AppRouteGuard implements CanActivate {
	constructor(private appContentStorageService: AppContentStorageService) { }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | boolean {
		if (route && route.url && route.url[0]) {
			switch (String(route.url[0]).toLowerCase()) {
				case AppRoutes.login.path.toLowerCase(): this.visibility(0, 0, 0, 1, 0); break;
				case AppRoutes.error.path.toLowerCase(): this.visibility(0, 0, 0, 1, 0); break;
				case AppRoutes.redirectError.path.toLowerCase(): this.visibility(0, 0, 0, 1, 0); break;
				case AppRoutes.detour.path.toLowerCase(): this.visibility(0, 0, 0, 0, 0); break;
				case AppRoutes.letterPreview.path.toLowerCase(): this.visibility(0, 0, 0, 0, 0); break;
                case AppRoutes.esenderCheck.path.toLowerCase(): this.visibility(0, 0, 0, 0, 0); break;
                case AppRoutes.researchType.path.toLowerCase(): this.visibility(1, 1, 1, 1, 1); break;

				default: this.visibility(1, 1, 1, 1, 1); break;
			}
		} else {
			this.visibility(1, 1, 1, 1, 1);
		}
		return true;
	}

	public visibility(
		header: boolean | number,
		menu: boolean | number,
		notification: boolean | number,
		loader: boolean | number,
		footer: boolean | number,
	) {
		this.appContentStorageService.headerVisibility(!!header);
		this.appContentStorageService.menuVisibility(!!menu);
		this.appContentStorageService.notificationVisibility(!!notification);
		this.appContentStorageService.loaderVisibility(!!loader);
		this.appContentStorageService.footerVisibility(!!footer);
  }

  public checkUserGroups(user: IUserModel, groups: string[]): boolean {
    let userGroups: string[] = user.groups || [];
    let inGroups: boolean = false;
    for (let el of userGroups) {
      if (groups.find(x => x == el)) {
        inGroups = true;
        break;
      }
    }

    if (!inGroups) {
      return false;
    } else {
      this.visibility(1, 1, 1, 1, 1);
    }

    return true;
  }
}
