import { Pipe, PipeTransform } from '@angular/core';
import { ICpiRowFieldData } from "./../interfaces/cpi-row-field-data.interface";

@Pipe({ name: 'subsampleFieldLoi', pure: true })
export class SubsampleFieldLoiPipe implements PipeTransform {

  transform(supplierCpiNumber: number, cpiRowFieldData: ICpiRowFieldData[]): number {
    let fieldLOI: number = 0;
    if (cpiRowFieldData) {
      for (let c of cpiRowFieldData) {
        if (c.supplierCpiNumber === supplierCpiNumber) {
          fieldLOI = c.fieldLoi;
        }
      }
    }
    return fieldLOI;
  }

}
