import { Directive, Input, Output, EventEmitter } from "@angular/core";
import { NgControl } from "@angular/forms";
import { debounceTime } from 'rxjs/operators';
//import "rxjs/add/operator/debounceTime";
//import "rxjs/add/operator/distinctUntilChanged";

@Directive({ selector: "[ngModel][debounce]" })
export class DebounceDirective {
	@Output() onDebounce = new EventEmitter<any>();
	@Input("debounce") debounceTime: number = 0;

	private isFirstChange: boolean = true;

    constructor(private model: NgControl) { }

	ngOnInit() {
        this.model.valueChanges!
            .pipe(
                debounceTime(this.debounceTime)
            ).subscribe(modelValue => {
				if (this.isFirstChange) {
					this.isFirstChange = false;
				} else {
					this.onDebounce.emit(modelValue);
				}
			});
	};
}
