import { Pipe, PipeTransform } from '@angular/core';
import { ICpiRowFieldData } from "./../interfaces/cpi-row-field-data.interface";

@Pipe({ name: 'subsampleFieldIncidence', pure: true })
export class SubsampleFieldIncidencePipe implements PipeTransform {

  transform(supplierCpiNumber: number, cpiRowFieldData: ICpiRowFieldData[]): number {
    let fieldIncidence: number = -1;
    if (cpiRowFieldData) {
      for (let c of cpiRowFieldData) {
        if (c.supplierCpiNumber === supplierCpiNumber) {
          fieldIncidence = c.fieldIncidence;
        }
      }
    }
    return fieldIncidence;
  }

}
