import { Injectable } from "@angular/core";
import { CanActivate } from "@angular/router";
import { AppContentStorageService } from "../services/app-content-storage.service";
import { UserStorageService } from "../services/user-storage.service";
import { AppSettings } from "../settings/app.settings";
import { AppRouteGuard } from "./app.routing.guard";
import { Observable } from "rxjs/internal/Observable";

@Injectable()
export class LockStatusGuard extends AppRouteGuard implements CanActivate {

  private lockStatusGroup: string;

  constructor(private appaContentStorageService: AppContentStorageService,
    private appSettings: AppSettings,
    private userStorageService: UserStorageService) {
    super(appaContentStorageService);
    this.lockStatusGroup = this.appSettings.LockUnlockStatusGroup;
  }

  canActivate(): Observable<boolean> {
    return new Observable((result) => {
      if (this.userStorageService.user && this.userStorageService.user.groups.length > 0) {
        result.next(this.checkUserGroups(this.userStorageService.user, [this.lockStatusGroup]));
      } else {
        this.userStorageService.userSubscription.subscribe(user => {
          result.next(this.checkUserGroups(user, [this.lockStatusGroup]));
        });
      }
    });
  }
}

