import { ArrayExtensions } from "../utilities/array.extensions";

export interface ISubsampleCopyItems {
	sampleQuotas?: boolean;
	surveyQuotas?: boolean;
  reportingVariables?: boolean;
  profilingQuestions?: boolean;
	sampleBlend?: boolean;
	subsampleSetting?: boolean;
	eligibilityRules?: boolean;
	surveyParameters?: boolean;
	sampleOptimizationSettings?: boolean;
	communicationSetup?: boolean;

}

export class SubsampleCopyItems implements ISubsampleCopyItems {
	sampleQuotas?: boolean;
	surveyQuotas?: boolean;
  reportingVariables?: boolean;
  profilingQuestions?: boolean;
	sampleBlend?: boolean;
	subsampleSetting?: boolean;
	eligibilityRules?: boolean;
	surveyParameters?: boolean;
	sampleOptimizationSettings?: boolean;
	communicationSetup?: boolean;

	constructor(model?: ISubsampleCopyItems) {
		ArrayExtensions.deepCopy.apply(this, [model]);
	}

}
