import { Component, OnInit } from "@angular/core";

import { AppContentStorageService } from "./services/app-content-storage.service";
import { AppSettings } from "./settings/app.settings";
import { AuthenticationService } from "./services/authentication.service";
import { LoadingAnimationService } from "./services/loading-animation.service";
import { UserStorageService } from "./services/user-storage.service";
import { IUserModel, UserModel } from "./models/user.model";

@Component({
	selector: "sample-one",
	template: `
		<div class="loading-animation-container" [hidden]="!showLoader" *ngIf="visibleLoader" data-automation-app-loading-div>
			<div class="loading-animation">Loading...</div>
		</div>

		<navbar *ngIf="visibleHeader"
				[user]="user"
				[showMenu]="showMenu"
				[showNotification]="showNotification"
				(onShowMenu)="onShowMenu($event)"
				(onShowNotification)="onShowNotification($event)"></navbar>

		<menu-left	*ngIf="visibleMenu"
					[user]="user"
					[show]="showMenu"
          (onShowMenuChanged)="showMenu=$event"></menu-left>

		<notification	*ngIf="visibleNotification"
						[show]="showNotification"></notification>

		<div class="page-min-height" (click)="hideSides()">
			<router-outlet></router-outlet>
		</div>

		<br />

		<footer (click)="hideSides()" *ngIf="visibleFooter"></footer>
	`,
	styles: [`.page-min-height { min-height: 700px; }`]
})
export class AppComponent implements OnInit {
	user: IUserModel = new UserModel(this.appSettings);

	visibleHeader = false;
	visibleMenu = false;
	visibleNotification = false;
	visibleLoader = false;
	visibleFooter = false;

	showMenu = false;
	showNotification = false;
	showLoader = false;

	constructor(
		private appContentStorageService: AppContentStorageService,
		private appSettings: AppSettings,
		private authenticationService: AuthenticationService,
		private loadingAnimationService: LoadingAnimationService,
		private userStorageService: UserStorageService,
	) { }

	ngOnInit() {
		this.userStorageService.userSubscription.subscribe(user =>  this.user = user);

		this.appContentStorageService.headerSubscription.subscribe(state => this.visibleHeader = state);
		this.appContentStorageService.menuSubscription.subscribe(state => this.visibleMenu = state);
		this.appContentStorageService.notificationSubscription.subscribe(state => this.visibleNotification = state);
		this.appContentStorageService.loaderSubscription.subscribe(state => this.visibleLoader = state);
		this.appContentStorageService.footerSubscription.subscribe(state => this.visibleFooter = state);

		this.loadingAnimationService.loaderSubscription.subscribe(showLoader => {
			setTimeout(() => {
				this.showLoader = showLoader;
			});
		});

		this.appSettings.firstTimeLoad = true;
		const userData = this.authenticationService.userData;
		this.appSettings.firstTimeLoad = false;
		if (userData) { this.userStorageService.getUser(<any><number>userData.userId); }
	}

	onShowMenu(showMenu: boolean) { this.showMenu = showMenu; }

	onShowNotification(showNotification: boolean) { this.showNotification = showNotification; }

	hideSides() { this.showMenu = false; this.showNotification = false; }
}
