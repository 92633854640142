import { Pipe, PipeTransform } from '@angular/core';
import { ISubsampleCostItem } from "../models/subsample-cost-item.model";

@Pipe({ name: 'supplierDiscountPercent', pure: true })
export class SupplierDiscountPercentPipe implements PipeTransform {

  transform(subsamples: ISubsampleCostItem[]): number {
    return subsamples[0].cpiItems[subsamples[0].cpiItems.length - 1].apiDiscount;
  }

}
