import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable()
export class NotificationService {
	private _notification = new Subject<string>();

	notifications: string[] = [];

	notification = this._notification.asObservable();

	pushNotification(notification: string) {
		if (notification) {
			this.notifications.push(notification);
			this._notification.next(notification);
		}
	}

	removeNotification(index: number) {
		if (index >= 0 && this.notifications[index]) {
			this.notifications.splice(index, 1);
			this._notification.next();
		}
	}
}
