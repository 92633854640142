import { IAnyObject } from "./../interfaces/any-object.interface";

export class ArrayExtensions {
	/*
	static deepCopy(oldObj: any) {
		let newObj = oldObj;
		if (oldObj && typeof oldObj === "object") {
			newObj = Object.prototype.toString.call(oldObj) === "[object Array]" ? [] : {};
			for (let i in oldObj) {
				if (oldObj.hasOwnProperty(i)) {
					newObj[i] = this.deepCopy(oldObj[i]);
				}
			}
		}
		return newObj;
	}
	*/

	private static isEmptyObject(obj: any): boolean {
		let ndx: string;
		for (ndx in obj) {
			if (obj.hasOwnProperty(ndx)) {
				return false;
			}
		}
		return true;
	}

	static copyArray<T>(ctor: { new (initializer?: any): T }, arr: Array<T>): (Array<T> | undefined) {
		if (arr && arr instanceof Array) {
			let index: number, element: T, lenOf: number, result: Array<T> = [];
			for (index = 0, lenOf = arr.length; index < lenOf; index++) {
				element = ctor && typeof arr[index] === "object" ? new ctor(arr[index]) : arr[index];
				result.push(element);
			}
			return result;
		} else {
			return undefined;
		}
	}

	static deepCopy(
		initializer: IAnyObject,
		skip: string[] = [],
		// transform: (txt: string) => string = null,
		// stack: Array<any> = []
	): void {
		let transform: any = null,
			stack: Array<any> = [],
			ndx1: string | any,
			ndx2: string | any,
			element: any,
			prevValue: any,
			len: number,
			isEmptyObject: boolean = ArrayExtensions.isEmptyObject(this);
		if (initializer) {
			for (ndx1 in initializer) {
				if (ndx1) {
					// normalize the indexes
					ndx2 = typeof ndx1 === "string" ? ndx1 : ndx1.toString();
					ndx1 = typeof ndx1 === "string"
						? (!transform ? ndx1.charAt(0).toLowerCase() + ndx1.slice(1) : transform(ndx1))
						: ndx1.toString();
					if (
						(isEmptyObject || this.hasOwnProperty(ndx1))
						&& (initializer.hasOwnProperty(ndx1) || initializer.hasOwnProperty(ndx2))
						&& skip.indexOf(ndx1) < 0
					) {
						element = initializer[ndx1] || initializer[ndx2];
						if (element && typeof element === "object") {
							len = stack.length;
							prevValue = null;
							while (len--) {
								if (stack[len] === element) {
									prevValue = element;
								}
							}
							if (prevValue) {
								this[ndx1] = prevValue;
							} else {
								if (!this[ndx1]) {
									this[ndx1] = element instanceof Array ? [] : new element.constructor();
								}
								stack.push(element);
								ArrayExtensions.deepCopy.apply(this[ndx1], [element, skip, transform, stack]);
							}
						} else {
							this[ndx1] = element;
						}
					}
				}
			}
		}
	}

	static aggregate<T, TSeed>(
		initializer: Array<T>,
		seed: TSeed,
		callbackfn: (seed: TSeed, value: T, index: number, array: T[]) => TSeed,
		thisArg?: any,
		fromIndex?: number
	): TSeed {
		let n, k,
			t = Object(initializer),
			len = t.length >>> 0;
		n = +(<number>fromIndex) || len - 1;

		if (Math.abs(n) === Infinity) {
			n = len - 1;
		}

		if (n >= len) {
			return seed;
		}

		for (k = n >= 0 ? Math.min(n, len - 1) : len - Math.abs(n); k >= 0; k--) {
			if (k in t) {
				seed = callbackfn.apply(thisArg || undefined, [seed, t[k], k, t]);
			}
		}

		return seed;
	};

	/**
	* derived from polyfill https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/indexOf
	* get the index of a searched element based on the result of callbackfn
	*/
	static partialIndexOf<T>(initializer: Array<T>, callbackfn: (value: T, index: number, array: T[]) => boolean, thisArg?: any, fromIndex?: number): number {
		let k: number,
			len: number,
			n: number,
			O: Array<T>;

		// 1. Let O be the result of calling ToObject passing
		//    the this value as the argument.
		if (!initializer) { return -1; }

		O = Object(initializer);

		// 2. Let lenValue be the result of calling the Get
		//    internal method of O with the argument "length".
		// 3. Let len be ToUint32(lenValue).
		len = O.length >>> 0;

		// 4. If len is 0, return -1.
		if (len === 0) {
			return -1;
		}

		// 5. If argument fromIndex was passed let n be
		//    ToInteger(fromIndex); else let n be 0.
		n = +(<number>fromIndex) || 0;

		if (Math.abs(n) === Infinity) {
			n = 0;
		}

		// 6. If n >= len, return -1.
		if (n >= len) {
			return -1;
		}

		// 7. If n >= 0, then Let k be n.
		// 8. Else, n<0, Let k be len - abs(n).
		//    If k is less than 0, then let k be 0.
		k = Math.max(n >= 0 ? n : len - Math.abs(n), 0);

		// 9. Repeat, while k < len
		while (k < len) {
			// a. Let Pk be ToString(k).
			//   This is implicit for LHS operands of the in operator
			// b. Let kPresent be the result of calling the
			//    HasProperty internal method of O with argument Pk.
			//   This step can be combined with c
			// c. If kPresent is true, then
			//    i.  Let elementK be the result of calling the Get
			//        internal method of O with the argument ToString(k).
			//   ii.  Let same be the result of applying the
			//        Strict Equality Comparison Algorithm to
			//        searchElement and elementK.
			//  iii.  If same is true, return k.
			if (k in O && callbackfn.apply(thisArg || undefined, [O[k], k, O])) {
				return k;
			}
			k += 1;
		}

		return -1;
	};

	/**
	 * derived from polyfill https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/lastIndexOf
	 * get the index of a searched element based on the result of callbackfn
	 */
	static partialLastIndexOf<T>(initializer: Array<T>, callbackfn: (value: T, index: number, array: T[]) => boolean, thisArg?: any, fromIndex?: number): number {
		if (initializer === void 0 || initializer === null) { return -1; }

		let n, k,
			t = Object(initializer),
			len = t.length >>> 0;
		if (len === 0) {
			return -1;
		}

		n = +(<number>fromIndex) || len - 1;

		if (Math.abs(n) === Infinity) {
			n = len - 1;
		}

		if (n >= len) {
			return -1;
		}

		for (k = n >= 0 ? Math.min(n, len - 1) : len - Math.abs(n); k >= 0; k--) {
			if (k in t && callbackfn.apply(thisArg || undefined, [t[k], k, t])) {
				return k;
			}
		}
		return -1;
	};
}
