import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";

import { AuthenticationService } from "../services/authentication.service";
import { IUserModel } from "../models/user.model";
import { AdminGuard } from "../guards/admin.guard";
import { ReportsGuard } from "../guards/reports.guard";
import { TroubleshootingGuard } from "../guards/troubleshooting.guard";
import { RouterManagementGuard } from "../guards/router-management.guard";
import { Observable } from "rxjs";
import { DemReportingGuard } from "../guards/dem-reporting.guard";

@Component({
  selector: "menu-left",
  template: `
<div class="sidebar sidebar-left sb-active pattern-default padding-xs"  [ngClass]="{'sidebar-left-open': show}">
   <div class="text-center margin-top-md margin-bottom-md" data-automation-left-menu-user-avatar-div>
      <!--<img style="width:125px; height:125px; border-radius: 62px;" alt="User Avatar" src="{{user?.employeeDirectoryLink}}">-->
   </div>
   <div class="font-size-lg text-center margin-bottom-md" *ngIf="user?.displayName">
      Welcome, <a data-automation-left-menu-user-name-a>{{user.displayName}}</a>
   </div>
   <div class="list-group margin-bottom-xs">
      <a routerLink="dashboard" (click)="hideMenu()" class="list-group-item" data-automation-left-menu-dashboard-a><i class="fa fa-home margin-right-xxs" aria-hidden="true"></i> Dashboard</a>
   </div>
   <div class="list-group margin-bottom-xs">
      <a href="#sub-group2" (click)="toolsOnClick()" class="list-group-item" data-toggle="collapse" data-automation-left-menu-admin-a>
      <span *ngIf="toogleClassTools"><i class="fa fa-caret-up" aria-hidden="true"></i></span>
      <span *ngIf="!toogleClassTools"><i class="fa fa-caret-down" aria-hidden="true"></i></span>
      Tools</a>
   </div>
   <div id="sub-group2" class="sub-group collapse" aria-expanded="false" style="">
      <a routerLink="tools/convert-respondentids" (click)="hideMenu()" class="list-group-item sub-item"><i class="fa fa-tools margin-right-xxs" aria-hidden="true"></i> Convert Respondent Ids</a>
      <a routerLink="tools/decrypt-respondentids" (click)="hideMenu()" class="list-group-item sub-item"><i class="fa fa-tools margin-right-xxs" aria-hidden="true"></i> Decrypt Respondent Ids</a>
      <a routerLink="tools/encrypt-respondentids" (click)="hideMenu()" class="list-group-item sub-item"><i class="fa fa-tools margin-right-xxs" aria-hidden="true"></i> Encrypt Respondent Ids</a>
   </div>
   <div class="list-group margin-bottom-xs" *ngIf="showTroubleshooting">
      <a routerLink="troubleshooting" (click)="hideMenu()" class="list-group-item">
      <i class="fa fa-wrench margin-right-xxs" aria-hidden="true"></i> Troubleshooting
      </a>
   </div>
   <div class="list-group margin-bottom-xs" *ngIf="showAdmin">
      <a routerLink="systemSettings" (click)="hideMenu()" class="list-group-item">
      <i class="fa fa-cog margin-right-xxs" aria-hidden="true"></i> System Settings
      </a>
   </div>
   <div class="list-group margin-bottom-xs" *ngIf="showRouterManagement">
      <a href="#sub-group3" (click)="routerManagementOnClick()" class="list-group-item" data-toggle="collapse" data-automation-left-menu-admin-a>
      <span *ngIf="toogleClassRouterManagement"><i class="fa fa-caret-up" aria-hidden="true"></i></span>
      <span *ngIf="!toogleClassRouterManagement"><i class="fa fa-caret-down" aria-hidden="true"></i></span>
      Router Management</a>
   </div>
   <div id="sub-group3" class="sub-group collapse" aria-expanded="false">
      <a routerLink="routerManagement/dynamic-router" (click)="hideMenu()" class="list-group-item sub-item"><i class="fa fa-sort-numeric-down margin-right-xxs" aria-hidden="true"></i> Dynamic Router</a>
      <a routerLink="routerManagement/survey-health" (click)="hideMenu()" class="list-group-item sub-item"><i class="fa fa-heartbeat margin-right-xxs" aria-hidden="true"></i> Survey Health</a>
      <a routerLink="routerManagement/health-action-center" (click)="hideMenu()" class="list-group-item sub-item"><i class="fa fa-heartbeat margin-right-xxs" aria-hidden="true"></i> Health Action Center</a>
   </div>
   <div *ngIf="(showMBReports || showDemReports)" class="list-group margin-bottom-xs">
      <a href="#sub-group1" (click)="reportsOnClick()" class="list-group-item" data-toggle="collapse" data-automation-left-menu-admin-a>
      <span *ngIf="toogleClass"><i class="fa fa-caret-up" aria-hidden="true"></i></span>
      <span *ngIf="!toogleClass"><i class="fa fa-caret-down" aria-hidden="true"></i></span>
      Reports</a>
   </div>
   <div id="sub-group1" class="sub-group collapse" aria-expanded="false" style="">
      <div *ngIf="showMBReports"><a routerLink="reports/monthlybilling" (click)="hideMenu()" class="list-group-item sub-item"><i class="fa fa-file margin-right-xxs" aria-hidden="true"></i> Monthly Billing</a></div>
      <div *ngIf="showDemReports"><a routerLink="reports/DEMReport" (click)="hideMenu()" class="list-group-item sub-item"><i class="fa fa-file margin-right-xxs" aria-hidden="true"></i> Dead End Message Report</a></div>
   </div>
   <div class="list-group margin-bottom-xs">
      <a (click)="logout()" class="list-group-item" data-automation-left-menu-logout-a><i class="fa fa-sign-out-alt margin-right-xxs" aria-hidden="true"></i> Logout</a>
   </div>
   <div class="list-group margin-bottom-xl text-center">
     Need help?
      <br/> Contact us at
      <a  href="https://ipsos.service-now.com/ess/" target="_blank">https://ipsos.service-now.com/ess/</a>
   </div>
</div>
`,
  styles: [`
		.sidebar-left {
			left: -307px;
			width: 307px;
		}
		.sidebar-left-open {
			left: 0;
		}
	`]
})
export class MenuLeftComponent implements OnInit {
  @Input() user: IUserModel;
  @Input() show: boolean;

  @Output() onShowMenuChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  toogleClass: boolean = false;
  toogleClassTools: boolean = false;
  toogleClassRouterManagement: boolean = false;
  showAdmin: boolean = false;
  showMBReports: boolean = false;
  showRouterManagement: boolean = false;
  showTroubleshooting: boolean = false;
  showDemReports: boolean = false; 

  constructor(private authenticationService: AuthenticationService,
    private adminGuard: AdminGuard,
    private reportsGuard: ReportsGuard,
    private troubleshootingGuard: TroubleshootingGuard,
    private routerManagementGuard: RouterManagementGuard,
    private demReportingGuard: DemReportingGuard) { }

  ngOnInit() {
    let adminGuardObservable: Observable<boolean> = this.adminGuard.canActivate();
    let reportsGuardObservable: Observable<boolean> = this.reportsGuard.canActivate();
    let routerManagementGuardObservable: Observable<boolean> = this.routerManagementGuard.canActivate();
    let troubleshootingGuardObservable: Observable<boolean> = this.troubleshootingGuard.canActivate();
    let demReportingGuardObservable: Observable<boolean> = this.demReportingGuard.canActivate(); 

    adminGuardObservable.subscribe((result) => { this.showAdmin = result; });
    reportsGuardObservable.subscribe((result) => { this.showMBReports = result; });
    routerManagementGuardObservable.subscribe((result) => { this.showRouterManagement = result; });
    troubleshootingGuardObservable.subscribe((result) => { this.showTroubleshooting = result; });
    demReportingGuardObservable.subscribe((result) => { this.showDemReports = result; }); 
  }

  hideMenu() {
    this.onShowMenuChanged.emit(false);
  }

  logout() { this.authenticationService.relogin(); }

  reportsOnClick() {
    this.toogleClass = !this.toogleClass;
  }

  toolsOnClick() {
    this.toogleClassTools = !this.toogleClassTools;
  }

  routerManagementOnClick() {
    this.toogleClassRouterManagement = !this.toogleClassRouterManagement;
  }
}
