import { Component } from "@angular/core";

@Component({
	selector: "footer",
	template: `
		<div class="container-fluid border-top">
			<div class="panel margin-bottom-md">
				<a href="http://www.ipsos.com/" target="_blank"><img style="height:18px;" src="assets/img/logo/ipsos_logo.svg" alt="Ipsos"></a>
			</div>
		</div>
	`
})
export class FooterComponent { }
