import { IDictionary } from "./../interfaces/dictionary.interface";
import { IAnyObject } from "./../interfaces/any-object.interface";

export class StringExtensions {
	public static formatByName(text: string, params: IDictionary<string | number>): string {
		if (!text || typeof text !== "string" || !params) { return text; }

		let p: string;
		let curly: RegExp;

		for (p in params) {
			if (params.hasOwnProperty(p)) {
				curly = new RegExp("\{{1}" + p + "\}{1}", "gi");
				if (curly.test(text)) {
					text = text.replace(curly, params[p].toString());
				}
			}
		}

		return text;
	}

	public static jsonParse(text: string): IAnyObject {
		let ret: IAnyObject;
		try {
			ret = JSON.parse(text);
		} catch (ex) {
			ret = {};
		}
		return ret;
	}

	public static jsonQueryString(json: IAnyObject, separator = "&", keyValueSeparator = "="): string {
		let text = "";
		for (let p in json) {
			if (json.hasOwnProperty(p)) {
				text += (text.length === 0 ? "" : (separator || "&")) + p + (keyValueSeparator || "=") + json[p];
			}
		}
		return text;
	}

	public static generateUUID(): string {
		let seed = new Date().getTime();
		if (window.performance && typeof window.performance.now === "function") {
			seed += performance.now(); // use high-precision timer if available
		}
		let uuid: string = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
			/[xy]/g,
			function (char: string): string {
				/*jshint bitwise: false*/
				let r = (seed + Math.random() * 16) % 16 | 0;
				seed = Math.floor(seed / 16);
				return (char === "x" ? r : (r & 0x3 | 0x8)).toString(16);
				/*jshint bitwise: true*/
			}
		);
		return uuid;
  }

  public static capitalize(value: string): string {
    if (value) {
      return value.charAt(0).toUpperCase() + value.slice(1);
    }
  }
}
