import {timer as observableTimer, throwError as observableThrowError,  Observable } from 'rxjs';
import {map, mergeMap, catchError} from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpErrorResponse } from "@angular/common/http";
import { IDictionary } from "./../interfaces/dictionary.interface";
import { StringExtensions } from '../../app/utilities/feza-utilities/string.extensions';


//import "rxjs/add/operator/toPromise";
//import "rxjs/add/operator/map";
//import "rxjs/add/operator/mergeMap";
//import "rxjs/add/operator/catch";
//import "rxjs/add/observable/throw";
//import "rxjs/add/observable/timer";

import { AppSettings } from "../settings/app.settings";
import { LoginToken } from "./token";
import { AuthenticationService } from "../services/authentication.service";
import { LoadingAnimationService } from "../services/loading-animation.service";

enum HttpMethod {
	get = 1,
	post = 2,
	put = 3,
  delete = 4,
  file = 5,
  fileBlob = 6,
  fileUploadDonwload = 7
}

@Injectable()
export class HttpClientService {
	constructor(
		private appSettings: AppSettings,
		private authenticationService: AuthenticationService,
    private http: HttpClient,
		private loadingAnimationService: LoadingAnimationService
	) { }

	private static formatUrl(url: string, params?: IDictionary<any>): string {
		let queryString = "";
		let p: string;
		let curly: RegExp;
		if (params) {
			for (p in params) {
				if (params.hasOwnProperty(p)) {
					curly = new RegExp("\{{1}" + p + "\}{1}", "gi");
					if (curly.test(url)) {
						url = url.replace(curly, params[p]);
					} else {
                        queryString += (queryString !== "" ? "&" : "") + p + "=" + encodeURIComponent(params[p]);
					}
				}
			}
		}
		return (url + (queryString ? ((url.indexOf("?") >= 0 ? "&" : "?") + queryString) : ""));
	}

    private authorizationHeader(customHeaders: IDictionary<string | number | boolean> | undefined): HttpHeaders {
    let headers = new HttpHeaders();
    headers = headers.append("Authorization", "Bearer " + this.authenticationService.authToken || "");
		return this.addCustomHeaders(customHeaders, headers);
	}

    private addCustomHeaders(customHeaders: IDictionary<string | number | boolean> | undefined, appHeaders: HttpHeaders): HttpHeaders {
		if (customHeaders) {
			for (let header in customHeaders) {
				if ((<Object>customHeaders).hasOwnProperty(header)) {
					appHeaders = appHeaders.append(header, <string>customHeaders[header]);
				}
			}
      }
      appHeaders = appHeaders.append('X-Correlation-ID', StringExtensions.generateUUID());
		return appHeaders;
	}

	private request_AuthHeader(
		method: HttpMethod,
		url: string,
    params: IDictionary<string | number | boolean | Date> | undefined,
		body: any | undefined,
    headers: IDictionary<string | number | boolean> | undefined   
	) {
		let appHeaders = this.authorizationHeader(headers);
		appHeaders = appHeaders.append("Content-Type", "application/json");
		return this.request_Method(method, url, params, body, appHeaders);
  }

  private request_AuthHeaderFile(
    method: HttpMethod,
    url: string,
    formData: FormData,
    params: IDictionary<string | number | boolean | Date> | undefined,
    body: any | undefined,
    headers: IDictionary<string | number | boolean> | undefined    
  ) {
    let appHeaders = this.authorizationHeader(headers);
    return this.request_Method(method, url, params, body, appHeaders, formData);
  }
  private request_3rdParty(
		method: HttpMethod,
		url: string,
    params: IDictionary<string | number | boolean | Date> | undefined,
		body: any | undefined,
    headers: IDictionary<string | number | boolean> | undefined    
	) {
    let appHeaders = new HttpHeaders();
		appHeaders = appHeaders.append("Content-Type", "application/json");
		appHeaders = this.addCustomHeaders(headers, appHeaders);
    return this.request_Method(method, url, params, body, appHeaders);
	}
	private request_Method(
		method: HttpMethod,
		url: string,
    params: IDictionary<string | number | boolean | Date> | undefined,
		body: any | undefined,
    headers: HttpHeaders,
    formData? :FormData
    ) {
        return (
			method === HttpMethod.get ? this.http.get(HttpClientService.formatUrl(url, params), { headers: headers }) :
			method === HttpMethod.post ? this.http.post(HttpClientService.formatUrl(url, params), body, { headers: headers }) :
			method === HttpMethod.put ? this.http.put(HttpClientService.formatUrl(url, params), body, { headers: headers }) :
      method === HttpMethod.delete ? this.http.delete(HttpClientService.formatUrl(url, params), { headers: headers }) :
      method === HttpMethod.file ? this.http.post(HttpClientService.formatUrl(url, params), formData, { headers: headers }) :
      method === HttpMethod.fileBlob ? this.http.post(HttpClientService.formatUrl(url, params), body, { headers: headers, responseType: 'blob' as 'json'}) :
      method === HttpMethod.fileUploadDonwload ? this.http.post(HttpClientService.formatUrl(url, params), formData, { headers: headers, responseType: 'blob' as 'json' }) :
			observableThrowError("method not implemented")
		);
	}

	private catch(
    error: HttpErrorResponse,
		method: HttpMethod,
		url: string,
    params: IDictionary<string | number | boolean | Date> | undefined,
		body: any | undefined,
		headers: IDictionary<string | number | boolean> | undefined,
		retries = 3
	): Observable<any> {
		if (error.status !== 401) {
			this.loadingAnimationService.hide();
      return error.error !== undefined ? observableThrowError(error.error) : observableThrowError(error);
    }
		return (
			(<Observable<any>>(
				new LoginToken(this.appSettings, this.authenticationService, this.http, this.loadingAnimationService).regenerate() ||
				observableTimer(1000))
			).pipe(
				mergeMap(() =>
					this.request_AuthHeader(method, url, params, body, headers).pipe(
						catchError(innerError => {
							retries--;
							if (retries <= 0) {
								this.loadingAnimationService.hide();
								this.authenticationService.relogin();
								return observableThrowError(innerError.statusText || innerError);
							}
							return this.catch(innerError, method, url, params, body, headers, retries);
						})))));
	}

  get(url: string, params?: IDictionary<string | number | boolean | Date>, headers?: IDictionary<string | number | boolean>) {
		return this.getSubscription(url, params, headers).toPromise();
	}
  get3rdParty(url: string, params?: IDictionary<string | number | boolean | Date>, headers?: IDictionary<string | number | boolean>) {
		return (this.request_3rdParty(HttpMethod.get, url, params, undefined, headers)
		);
	}
	getSubscription(url: string, params?: IDictionary<string | number | boolean | Date>, headers?: IDictionary<string | number | boolean>) {
		this.loadingAnimationService.show();
		return (this
			.request_AuthHeader(HttpMethod.get, url, params, undefined, headers).pipe(
			catchError(error => this.catch(error, HttpMethod.get, url, params, undefined, headers)),
			map(res => {
				this.loadingAnimationService.hide();
				return res;
			}),)
		);
	}

	post(url: string, params?: IDictionary<string | number | boolean>, body?: any, headers?: IDictionary<string | number | boolean>) {
		return this.postSubscription(url, params, body, headers).toPromise();
	}
	postSubscription(url: string, params?: IDictionary<string | number | boolean>, body?: any, headers?: IDictionary<string | number | boolean>) {
		this.loadingAnimationService.show();
		return (this
			.request_AuthHeader(HttpMethod.post, url, params, body, headers).pipe(
			catchError(error => this.catch(error, HttpMethod.post, url, params, body, headers)),
			map(res => {
				this.loadingAnimationService.hide();
				return res;
			}),)
		);
  }
  postFile(url: string, formData: FormData, params?: IDictionary<string | number | boolean | Date>, body?: any, headers?: IDictionary<string | number | boolean>) {
    this.loadingAnimationService.show();
    return (this
      .request_AuthHeaderFile(HttpMethod.file, url,formData, params, body, headers).pipe(
        catchError(error => this.catch(error, HttpMethod.post, url, params, body, headers)),
        map(res => {
          this.loadingAnimationService.hide();
          return res;
        }))
    );
  }

  put(url: string, params?: IDictionary<string | number | boolean | Date>, body?: any, headers?: IDictionary<string | number | boolean>) {
		return this.putSubscription(url, params, body, headers).toPromise();
	}
  putSubscription(url: string, params?: IDictionary<string | number | boolean | Date>, body?: any, headers?: IDictionary<string | number | boolean>) {
		this.loadingAnimationService.show();
		return (this
			.request_AuthHeader(HttpMethod.put, url, params, body, headers).pipe(
			catchError(error => this.catch(error, HttpMethod.put, url, params, body, headers)),
			map(res => {
				this.loadingAnimationService.hide();
				return res;
			}),)
		);
	}

  delete(url: string, params?: IDictionary<string | number | boolean | Date>, body?: any, headers?: IDictionary<string | number | boolean>) {
		return this.deleteSubscription(url, params, body, headers).toPromise();
	}
  deleteSubscription(url: string, params?: IDictionary<string | number | boolean | Date>, body?: any, headers?: IDictionary<string | number | boolean>) {
		this.loadingAnimationService.show();
		return (this
			.request_AuthHeader(HttpMethod.delete, url, params, body, headers).pipe(
			catchError(error => this.catch(error, HttpMethod.delete, url, params, body, headers)),
			map(res => {
				this.loadingAnimationService.hide();
				return res;
			}),)
		);
  }

  postFileSubscription(url: string, params?: IDictionary<string | number | boolean | Date>, body?: any, headers?: IDictionary<string | number | boolean>) {
    this.loadingAnimationService.show();
    return (this
      .request_AuthHeaderFile(HttpMethod.fileBlob, url, null, params, body, headers).pipe(
        catchError(error => this.catch(error, HttpMethod.fileBlob, url, params, body, headers)),
        map(res => {
          this.loadingAnimationService.hide();
          return res;
        }))
    );
  }

  postFileSubscriptionWithDownload(url: string, formData: FormData, params?: IDictionary<string | number | boolean | Date>, body?: any, headers?: IDictionary<string | number | boolean>) {
    this.loadingAnimationService.show();
    return (this
      .request_AuthHeaderFile(HttpMethod.fileUploadDonwload, url, formData, params, body, headers).pipe(
        catchError(error => this.catch(error, HttpMethod.fileBlob, url, params, body, headers)),
        map(res => {
          this.loadingAnimationService.hide();
          return res;
        }))
    );
  }
}
