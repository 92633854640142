import { Injectable } from "@angular/core";
import { Subject, of as observableOf } from "rxjs";
//import { Observable } from "rxjs";
import { catchError } from 'rxjs/operators';

import { AppSettings } from "../settings/app.settings";
import { IEventModel, EventModel } from "../models/event.model";
import { EventService } from "./event.service";

@Injectable()
export class EventStorageService {
	protected eventId: number;
	private eventSubject = new Subject<IEventModel>();
	private eventInProgress: number = 0;

	private _event: IEventModel = new EventModel(this.appSettings);
	get event() {
		return this._event;
	}

	eventSubscription = this.eventSubject.asObservable();
	showUserPanel = true;

	constructor(
		private appSettings: AppSettings,
		private eventService: EventService
	) { }

	getEventDetails(eventId: number) {
		this.eventId = eventId;
		if (!eventId) {
			this._event = new EventModel(this.appSettings);
			this.eventSubject.next(this._event);
		} else if (eventId === this._event.id || eventId === this.eventInProgress) {
			return;
		} else {
			this.eventInProgress = eventId;
			this.event.id = eventId;
			this.eventService
                .getEventDetails(eventId)
                .pipe(
                    catchError(() => observableOf(<IEventModel>{})))
                .subscribe(event => {
					if (!event || !event.id) { event = {}; }

					this.eventId = event.id || 0;
					this._event = new EventModel(this.appSettings, event || {});

					this.eventInProgress = 0;
					this.eventSubject.next(this._event);
				});
		}
	}

	storeEvent(event: IEventModel) {
		this._event = new EventModel(this.appSettings, event || {});
		this.eventSubject.next(this._event);
	}
}
