import { AppRoutes } from "../settings/routes";

export class Location {
	static get search() { return (window.location.href + "?").split("?")[1]; }

	static get host() { return window.location.host; }

	static get href() { return window.location.href; }
	static set href(link: string) {
		window.location.href = link;
		if (!window.opener) { window.location.reload(); }
	}

	static get pathname() {
		let hash = Location.href.split("/#");
		let pathname = window.location.pathname;
		return pathname !== "/" ? pathname : hash.length > 1 ? hash[1] : pathname;
	}

	static get isLocalHost() { return !(/^(local|dev)\./).test(document.location!.host); }

	static get isLoginPage() { return new RegExp("/" + AppRoutes.login.path, "i").test(Location.pathname); }

	static isActiveLink(link: string) { return new RegExp(String(link), "i").test(Location.pathname); }

	static encode(link: string) { return encodeURIComponent(String(link)); }
	static decode(link: string) { return decodeURIComponent(String(link)).replace("/#/%23/", "/#/"); }

	static reload() {
		window.location.reload();
	}
}
