import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
//import "rxjs/add/operator/toPromise";

import { AppSettings } from "../settings/app.settings";
import { AppRoutes } from "../settings/routes";
import { Cookies } from "../utilities/cookies";
import { IUserCookieModel } from "../models/user-cookie.model";
import { StringExtensions } from "../utilities/string.extensions";
import { Location } from "../utilities/location";

@Injectable()
export class AuthenticationService {
	constructor(
		private appSettings: AppSettings,
		private router: Router,
        private http: HttpClient
	) { }

	get isLoggedIn(): boolean {
		if (!Cookies.getItem(this.cookieAuthTokenName) ||
			!Cookies.getItem(this.cookieUserDataName) ||
			!Cookies.getItem(this.appSettings.CookieUserType)
		) {
			this.relogin();
			return false;
		}

		return true;
	}

	relogin() {
		if (Cookies.getItem(this.cookieAuthTokenName)) {
			Cookies.removeItem(this.cookieAuthTokenName);
		}
		if (Cookies.getItem(this.cookieUserDataName)) {
			Cookies.removeItem(this.cookieUserDataName);
		}
		if (Cookies.getItem(this.appSettings.CookieUserType)) {
			Cookies.removeItem(this.appSettings.CookieUserType);
		}

		this.redirectLogin();
	}

	get authToken(): string | undefined {
		let tokenCookie = Cookies.getItem(this.cookieAuthTokenName);
		if (!tokenCookie) {
			if (!this.userData || !this.userType) {
				this.relogin();
				return;
			}
		}

		return tokenCookie;
	}
	get userData(): IUserCookieModel | undefined {
		let userData = Cookies.getItem(this.cookieUserDataName);
		if (!userData) {
			this.relogin();
			return;
		}

		return StringExtensions.jsonParse(userData);
	}
	get userType(): string | undefined {
		let userType = Cookies.getItem(this.appSettings.CookieUserType);
		if (!userType) {
			this.relogin();
			return;
		}

		return userType;
	}

	private get cookieAuthTokenName(): string {
		return this.appSettings.CookieAuthToken + "_" + (Cookies.getItem(this.appSettings.CookieUserType) || "");
	}
	private get cookieUserDataName(): string {
		return this.appSettings.CookieUserData + "_" + (Cookies.getItem(this.appSettings.CookieUserType) || "");
	}

	private redirectLogin() {
		const login = AppRoutes.login.path;
		if (!Location.isLoginPage) {
			const redirect = login + (
				new RegExp("(^(\/){0,1}$|^\/" + login + ")", "i").test(Location.pathname) === false
					? "?s2=" + Location.encode(Location.href)
					: ""
			);
			if (this.appSettings.firstTimeLoad) {
				// first time loaded app, no point to reload it entirely to go to login
				this.router.navigateByUrl(redirect);
			} else {
				// using redirect instead of router to force refresh the browser
				Location.href =
					"//" + Location.host.replace(/^http(s){0,1}\:\/\//i, "") + (Location.host.endsWith("/") ? "" : "/") + "#/" +
					redirect;
			}
		}
	}
}
