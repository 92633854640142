import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { Observable, of } from 'rxjs';
import { catchError, map } from "rxjs/operators";
import { ADQuotaService } from "../services/ad-quota-builder.service";
import { AppContentStorageService } from "../services/app-content-storage.service";

@Injectable()
export class QuotaBuilderGuard implements CanActivate {

  constructor(private appaContentStorageService: AppContentStorageService,
    private adQuotaService: ADQuotaService,
    private router: Router  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.adQuotaService
      .isEnabled()
      .pipe(
        map(result => {
          if (result) {
            return true;
          }
          else {
            return false;
          }
        }),
        catchError(err => {
          return of(false);
        })
      );
      
  }
}
