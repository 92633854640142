import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'numberSign', pure: true })
export class NumberSignPipe implements PipeTransform {

  transform(value: number): string {
    return (value > 0) ? "+" + String(value) : String(value);
  }

}
