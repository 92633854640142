import { Injectable } from "@angular/core";
import { AppSettings } from "../settings/app.settings";
import { HttpClientService } from "../utilities/http.client";
import { Observable } from "rxjs";
import { IResearchType } from "../models/research-type.model";
import { IProductResearchTypeMapping } from "../models/product-research-type-mapping.model";

@Injectable()
export class ResearchTypeService {
	constructor(
        private http: HttpClientService,
		private appSettings: AppSettings
	) { }

	private urls = {
        "getResearchTypes": this.appSettings.AdminApiPath + "research-types-osm",
        "getProductResearchTypeMapping": this.appSettings.CommonApiPath + "product-research-type-mapping",
        "saveProductResearchTypeMappings": this.appSettings.CommonApiPath + "ProductResearchTypeMappings/mappingList"
	};

	getResearchTypes(): Observable<IResearchType[]> {
		return this.http.getSubscription(this.urls["getResearchTypes"], {});
    }

    getProductResearchTypeMapping(): Observable<IProductResearchTypeMapping[]> {
        return this.http.getSubscription(this.urls["getProductResearchTypeMapping"], {});
    } 

    saveProductResearchTypeMappings = (mappingList: IProductResearchTypeMapping[]): Observable<boolean> =>
        this.http.postSubscription(this.urls["saveProductResearchTypeMappings"], {}, mappingList );

}
